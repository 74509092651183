import { IconButton, Tooltip, chakra } from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { FC } from 'react';
import { CommentIconButtonProps } from '../model/tech-map.types.';
import styles from './comment-badge.module.scss';

export const CommentIconButton: FC<CommentIconButtonProps> = ({ count, ...props }) => {
	return (
		<Tooltip label="Комментарии" hasArrow placement="bottom">
			<IconButton
				position="relative"
				style={{ margin: 0 }}
				icon={
					<>
						<MadIcon module="basic" mode="default" size="default" type="outline" name="chat-text">
							{count !== 0 && (
								<chakra.div className={styles['badge']} position="absolute" top="-5px" right="-5px">
									<chakra.p className={styles['badge_count']}>{count}</chakra.p>
								</chakra.div>
							)}
						</MadIcon>
					</>
				}
				variant="ghost"
				colorScheme="tertiary"
				p="9px"
				_focus={{ boxShadow: 'none' }}
				{...props}
			/>
		</Tooltip>
	);
};
