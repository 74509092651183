import {
	CounteragentResponse,
	CounteragentSortingFields,
	useDeleteCounteragentMutation,
} from '@/shared/state/api/swagger';
import { Actions, CommonTable, DeleteModal, Loader, showErrorToast, showSuccessToast } from '@/shared';
import { TablePaginationConfig } from 'antd';
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface';
import { FC, ReactNode, useCallback, useState } from 'react';
import { COUNTER_AGENT_TABLE_COLUMNS } from '../model/columns';
import { CounterAgentCatalogTableProps } from '../model/counter-agent-table.types';
import { createPortal } from 'react-dom';
import { useDisclosure, useToast } from '@chakra-ui-kraud/react';
import styles from './counter-agent-table.module.scss';

export const CounterAgentTable: FC<CounterAgentCatalogTableProps> = ({
	data,
	page,
	pageSize,
	total,
	setQueryParams,
	isLoading,
	handleClick,
}) => {
	const handleTableChange = useCallback(
		(
			pagination: TablePaginationConfig,
			filters: Record<string, FilterValue | null>,
			sorter:
				| SorterResult<Partial<CounteragentSortingFields>>
				| SorterResult<Partial<CounteragentSortingFields>>[],
		) => {
			const sortedBy = Array.isArray(sorter) ? sorter[0].column?.key?.toString() : sorter.column?.key?.toString();
			const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
			const preparedSortDir = sortOrder ? (sortOrder === 'ascend' ? 'asc' : 'desc') : undefined;

			setQueryParams((prev) => ({
				...prev,
				orderBy: sortedBy as CounteragentSortingFields,
				orderDir: preparedSortDir,
			}));
		},
		[setQueryParams],
	);

	const [selectedAgent, setSelectedAgent] = useState<CounteragentResponse | null>(null);
	const [deleteAgent, { isLoading: isDeleteLoading }] = useDeleteCounteragentMutation();
	const { isOpen, onClose, onOpen } = useDisclosure();

	const columns: ColumnsType<CounteragentResponse> = [
		...COUNTER_AGENT_TABLE_COLUMNS,
		{
			key: 'actions',
			title: 'Действия',
			align: 'left',
			dataIndex: 'actions',
			className: styles['actions'],
			render: (_: ReactNode, row: CounteragentResponse) => {
				return (
					<Actions
						width={120}
						onDelete={() => {
							setSelectedAgent(row);
							onOpen();
						}}
						onEdit={() => {
							handleClick?.(row, 'edit');
						}}
						onCreate={() => {
							handleClick?.(row, 'create');
						}}
						tooltip={{
							edit: 'Редактировать контрагента',
							del: 'Удалить контрагента',
						}}
					/>
				);
			},
		},
	];

	const toast = useToast();

	const handleDeleteAgent = useCallback(() => {
		if (selectedAgent?.id) {
			deleteAgent({ counteragentId: selectedAgent?.id })
				.unwrap()
				.then(() => {
					onClose();
					showSuccessToast(toast, { description: 'Контрагент удален' });
				})
				.catch(() => {
					showErrorToast(toast, { description: 'При удалении контрагента произошла ошибка' });
				});
		}
	}, [selectedAgent?.id]);

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<CommonTable
						columns={columns}
						data={data}
						onChange={handleTableChange}
						pageSize={pageSize}
						total={total}
						page={page}
						setQueryParams={setQueryParams}
					/>
					{isOpen &&
						createPortal(
							<DeleteModal
								isOpen={isOpen}
								onClose={() => {
									onClose();
									setSelectedAgent(null);
								}}
								deleteRequestLoading={isDeleteLoading}
								onDelete={handleDeleteAgent}
								text={`Вы уверены, что хотите удалить контрагента «${selectedAgent?.name}»?`}
								title={'Удаление контрагента'}
							/>,
							document.body,
						)}{' '}
				</>
			)}
		</>
	);
};
