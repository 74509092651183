import type { FC } from 'react';

import { useCallback, useEffect } from 'react';

import {
	useLazyGetNumsQuery,
	useLazyGetUniqueCustomNumsQuery,
	useLazyGetUniquePreformCustomNumsQuery,
	useLazyGetUniqueProductCustomNumsQuery,
} from '@/shared/state/api/swagger';
import { selectNumOptions } from '@/entities/num';

import { FilterSelect } from './filter-select';
import { makeUniqueOptions } from '../../lib/make-unique-options';
import { useHandleSelectOption } from '../../lib/handle-select-option';

// TODO Нужно вынести однотипную логику

export const NumSelect: FC = () => {
	const [trigger, { options, isFetching }] = useLazyGetNumsQuery({
		selectFromResult: (result) => ({ ...result, options: selectNumOptions(result?.data?.payload) }),
	});

	const { handleSelectOption } = useHandleSelectOption('numStrict', options);

	const onSearch = useCallback(({ stringToSearch }: { stringToSearch: string }) => {
		trigger({ stringToSearch, pageSize: 1_000_000 });
	}, []);

	useEffect(() => {
		trigger({ pageSize: 1_000_000 });
	}, []);

	return (
		<FilterSelect
			label="НУМ"
			name="numStrict"
			options={options}
			isLoading={isFetching}
			onSearch={onSearch}
			onChange={handleSelectOption}
			alertName="Введите НУМ"
		/>
	);
};

export const NumForProductTechCardSelect: FC = () => {
	const [trigger, { options, isFetching }] = useLazyGetUniqueProductCustomNumsQuery({
		selectFromResult: (result) => ({
			...result,
			options: result.data?.payload?.map((num) => ({ value: num, label: num })) ?? [],
		}),
	});

	const onSearch = useCallback(({ stringToSearch }: { stringToSearch: string }) => {
		trigger({ stringToSearch });
	}, []);

	useEffect(() => {
		trigger({});
	}, []);

	return (
		<FilterSelect
			label="НУМ"
			name="numStrict"
			options={options}
			isLoading={isFetching}
			onSearch={onSearch}
			alertName="Введите НУМ"
		/>
	);
};

export const NumForPerformTechCardSelect: FC = () => {
	const [trigger, { options, isFetching }] = useLazyGetUniquePreformCustomNumsQuery({
		selectFromResult: (result) => ({
			...result,
			options: result.data?.payload?.map((num) => ({ value: num, label: num })) ?? [],
		}),
	});

	const onSearch = useCallback(({ stringToSearch }: { stringToSearch: string }) => {
		trigger({ stringToSearch });
	}, []);

	useEffect(() => {
		trigger({});
	}, []);

	return (
		<FilterSelect
			label="НУМ"
			name="numStrict"
			options={options}
			isLoading={isFetching}
			onSearch={onSearch}
			alertName="Введите НУМ"
		/>
	);
};

export const NumForAllTechCardSelect: FC = () => {
	const [trigger, { options, isFetching }] = useLazyGetUniqueCustomNumsQuery({
		selectFromResult: (result) => ({
			...result,
			options: result.data?.payload?.map((num) => ({ value: num, label: num })) ?? [],
		}),
	});

	const onSearch = useCallback(({ stringToSearch }: { stringToSearch: string }) => {
		trigger({ stringToSearch });
	}, []);

	useEffect(() => {
		trigger({});
	}, []);

	return (
		<FilterSelect
			label="НУМ"
			name="numStrict"
			options={options}
			isLoading={isFetching}
			onSearch={onSearch}
			alertName="Введите НУМ"
		/>
	);
};

export const NumSymbolSelect: FC = () => {
	const [trigger, { options, isFetching }] = useLazyGetNumsQuery({
		selectFromResult: (result) => ({ ...result, options: makeUniqueOptions(result?.data?.payload, 'symbol') }),
	});

	const onSearch = useCallback(({ stringToSearch }: { stringToSearch: string }) => {
		trigger({ stringToSearch, pageSize: 1_000_000 });
	}, []);

	useEffect(() => {
		trigger({ pageSize: 1_000_000 });
	}, []);

	return (
		<FilterSelect
			label="Марка материала"
			name="numSymbolStrict"
			options={options}
			isLoading={isFetching}
			onSearch={onSearch}
			alertName="Введите марку материaла"
		/>
	);
};

export const NumGostSelect: FC = () => {
	const [trigger, { options, isFetching }] = useLazyGetNumsQuery({
		selectFromResult: (result) => ({ ...result, options: makeUniqueOptions(result?.data?.payload, 'gost') }),
	});

	const onSearch = useCallback(({ stringToSearch }: { stringToSearch: string }) => {
		trigger({ stringToSearch, pageSize: 1_000_000 });
	}, []);

	useEffect(() => {
		trigger({ pageSize: 1_000_000 });
	}, []);

	return (
		<FilterSelect
			label="Стандарт материала"
			name="numGostStrict"
			options={options}
			isLoading={isFetching}
			onSearch={onSearch}
			alertName="Введите стандарт материaла"
		/>
	);
};

export const NumSortamentSelect: FC = () => {
	const [trigger, { options, isFetching }] = useLazyGetNumsQuery({
		selectFromResult: (result) => ({
			...result,
			options: makeUniqueOptions(result?.data?.payload, 'sortament'),
		}),
	});

	const onSearch = useCallback(({ stringToSearch }: { stringToSearch: string }) => {
		trigger({ stringToSearch, pageSize: 1_000_000 });
	}, []);

	useEffect(() => {
		trigger({ pageSize: 1_000_000 });
	}, []);

	return (
		<FilterSelect
			label="Сортамент"
			name="numSortamentStrict"
			options={options}
			isLoading={isFetching}
			onSearch={onSearch}
			alertName="Введите сортамент"
		/>
	);
};

export const NumGostSortamentSelect: FC = () => {
	const [trigger, { options, isFetching }] = useLazyGetNumsQuery({
		selectFromResult: (result) => ({
			...result,
			options: makeUniqueOptions(result?.data?.payload, 'gost_na_sortament'),
		}),
	});

	const onSearch = useCallback(({ stringToSearch }: { stringToSearch: string }) => {
		trigger({ stringToSearch, pageSize: 1_000_000 });
	}, []);

	useEffect(() => {
		trigger({ pageSize: 1_000_000 });
	}, []);

	return (
		<FilterSelect
			label="ГОСТ на сортамент"
			name="numGostSortamentStrict"
			options={options}
			onSearch={onSearch}
			alertName="Введите ГОСТ на сортамент"
			isLoading={isFetching}
		/>
	);
};
