import { TechCardStatus, TechCardStatuses } from '@/shared';
import { GetProductTechMapCommentsApiResponse } from '@/shared/state/api/swagger';

export const MAP_STATUS_TO_NAME: Record<TechCardStatus, string> = {
	[TechCardStatuses.released]: 'Отгружена',
	[TechCardStatuses.progress]: 'В работе',
	[TechCardStatuses.accepted_at_sgd]: 'Принята на СГД',
	[TechCardStatuses.canceled]: 'Аннулирована',
	[TechCardStatuses.partially_issued]: 'Частично выдана',
	[TechCardStatuses.completed]: 'Завершена',
	[TechCardStatuses.accepted_at_storage]: 'На складе',
	[TechCardStatuses.wasted]: 'Забракована',
};

export const TECH_MAP_ADDITIONAL_FILE_EXTENSIONS = {
	'application/pdf': ['.pdf'],
	'application/msword': ['.doc'],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
};

export const selectTechMapCommentsCount = (data?: GetProductTechMapCommentsApiResponse) => data?.length ?? 0;
