import { showErrorToast, showSuccessToast, SideModal } from '@/shared';
import { isFetchBaseQueryError } from '@/shared/state/api';
import {
	CounteragentResponse,
	useCreateCounteragentMutation,
	useUpdateCounteragentMutation,
} from '@/shared/state/api/swagger';
import { Button, ButtonGroup, useToast } from '@chakra-ui-kraud/react';
import { FC, useRef } from 'react';
import { COUNTER_AGENT_DUPLICATE_ERROR } from '../model/model';
import { CounterAgentFormModalProps } from '../model/counter-agent-form.types';
import { CounterAgentForm } from './counter-agent-form';

export const CounterAgentFormModal: FC<CounterAgentFormModalProps> = ({ isOpen, onClose, variant, selectedAgent }) => {
	const formRef = useRef<HTMLFormElement | null>(null);
	const [create, { isLoading: isCreateLoading }] = useCreateCounteragentMutation();
	const [update, { isLoading: isUpdateLoading }] = useUpdateCounteragentMutation();
	const isLoading = isCreateLoading || isUpdateLoading;
	const toast = useToast();
	const onSubmit = (data: CounteragentResponse) => {
		if (variant === 'create') {
			create({ counteragentCreateRequest: { name: data.name } })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'Контрагент создан' });
					onClose();
				})
				.catch((err) => {
					console.error(err);
					if (isFetchBaseQueryError(err)) {
						switch (err.status) {
							case COUNTER_AGENT_DUPLICATE_ERROR: {
								showErrorToast(toast, { title: `Контрагент ${data.name} уже существует` });
								break;
							}
							default: {
								showErrorToast(toast, { title: 'При создании контрагента произошла ошибка' });
							}
						}
					}
				});
		} else if (variant === 'edit' && selectedAgent?.id) {
			update({ counteragentId: selectedAgent?.id, counteragentUpdateRequest: { name: data.name } })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: 'Контрагент обновлен' });
					onClose();
				})
				.catch((err) => {
					console.error(err);
					if (isFetchBaseQueryError(err)) {
						switch (err.status) {
							case COUNTER_AGENT_DUPLICATE_ERROR: {
								showErrorToast(toast, { description: `Контрагент ${data.name} уже существует` });
								break;
							}
							default: {
								showErrorToast(toast, { description: 'При создании контрагента произошла ошибка' });
							}
						}
					}
				});
		}
	};

	return (
		<SideModal
			title={variant === 'edit' ? 'Редактирование контрагента' : 'Добавление контрагента'}
			isOpen={isOpen}
			onClose={onClose}
			maxWidth={'644px'}
			footer={
				<ButtonGroup>
					<Button size="md" variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => formRef?.current?.requestSubmit()}
						isLoading={isLoading}
					>
						{variant === 'edit' ? 'Сохранить' : 'Добавить контрагента'}
					</Button>
				</ButtonGroup>
			}
			onBlur={onClose}
		>
			<CounterAgentForm selectedAgent={selectedAgent} variant={variant} onSubmit={onSubmit} ref={formRef} />
		</SideModal>
	);
};
