import {
	useGetAllInitialsQuery,
	useGetPreformsQuery,
	useGetPreformTechMapByNumberQuery,
} from '@/shared/state/api/swagger';
import { chakra, Flex, Input, Table, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui-kraud/react';
import { FC, useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FormPreformModel } from '../../model/preform.types';
import _debounce from 'lodash/debounce';
import { DatePicker, Select } from 'antd';
import { selectPreforms } from '@/entities/preform';
import { DEFAULT_YEAR_FORMAT, NotFoundContentDropdown, useTechCard } from '@/shared';
import styles from './main.module.scss';
import clsx from 'clsx';
import { MainBlockPreformTypes } from './main.types';
import dayjs from 'dayjs';
import { selectEmployeesInitials } from '@/entities/employee';
import { byStepId } from '../../lib/sort-by-step-id';

export const MainBlockPreform: FC<MainBlockPreformTypes> = ({ isEditable, isPrinting }) => {
	const { isCreationMode } = useTechCard();
	const { control, setValue } = useFormContext<FormPreformModel>();

	const mapNumber = Number(useParams().number);
	const [preformQuery, setPreformQuery] = useState('');
	const { data: preformMap } = useGetPreformTechMapByNumberQuery(
		{ preformTechMapNumber: mapNumber },
		{
			skip: !mapNumber || Number.isNaN(mapNumber),
		},
	);
	const debouncedPreformSearch = useCallback(
		_debounce((search: string) => setPreformQuery(search), 300),
		[],
	);

	const { options: employee } = useGetAllInitialsQuery(
		{ stringToSearch: '' },
		{
			selectFromResult: (result) => ({ ...result, options: selectEmployeesInitials(result?.data) }),
		},
	);
	const {
		data: preforms,
		options: preformOptions,
		isFetching: isPreformsFetching,
	} = useGetPreformsQuery(
		{ stringToSearch: preformQuery },
		{
			selectFromResult: (result) => ({
				...result,
				options: selectPreforms(result.data?.payload),
			}),
		},
	);
	const handlePreformSelect = (value: string | number) => {
		setValue('preform_operations', []);
		const selectedPreform = preforms?.payload?.find((preform) => preform.id === Number(value));

		if (!selectedPreform) {
			return;
		}

		setValue('preform.name', selectedPreform.name);
		setValue('preform_num', undefined, { shouldValidate: true });
		setValue('preform_num.num', undefined, { shouldValidate: true });

		if (selectedPreform.file || selectedPreform.file_id) {
			setValue('file_id', selectedPreform.file?.id ?? selectedPreform.file_id);
		}

		const sortedOperationsTypes = [...(selectedPreform?.operation_types ?? [])]?.sort(byStepId);
		sortedOperationsTypes?.forEach((operation, index) => {
			setValue(`preform_operations.${index}.step_id`, operation.step_id ?? 0);
			setValue(`preform_operations.${index}.name`, operation.name || '');
		});
	};

	return (
		<Flex flex={'1'} className={clsx(styles['main-block'], styles['main-block-preform'])}>
			<Table>
				<Thead>
					<Tr>
						{!isPrinting && (
							<Th minW="240px" maxW="240px" w="240px">
								Шаблон процесса{' '}
							</Th>
						)}
						<Th w="120px">Маршрутная карта </Th>
						<Th width="140px">Дата</Th>
						<Th>Подпись</Th>
						<Th minW={'160px'} maxW={'360px'}>
							ФИО ПДС
						</Th>
					</Tr>
				</Thead>
				<Tbody className={styles['edit-table']}>
					<Tr>
						{!isPrinting && (
							<Td>
								{isCreationMode ? (
									<Controller
										name="preform.name"
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Select
												status={error ? 'error' : ''}
												tabIndex={1}
												showSearch
												placeholder="Поиск..."
												optionFilterProp="children"
												onChange={(e) => {
													onChange(e);
													handlePreformSelect(e);
												}}
												filterOption={() => true}
												onSearch={(search) => debouncedPreformSearch(search)}
												value={value}
												options={preformQuery ? preformOptions : []}
												style={{
													width: '100%',
													boxShadow: error?.message ? '0 0 0 1px #fc5e6e' : '',
												}}
												className={clsx(styles['custom-select'], styles['preform-select'])}
												notFoundContent={
													<NotFoundContentDropdown
														isFetchingNaming={isPreformsFetching}
														alertName={'Введите обозначение'}
													/>
												}
											/>
										)}
									/>
								) : (
									<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
										{preformMap?.preform?.name}
									</Text>
								)}
							</Td>
						)}
						<Td>
							{isCreationMode ? (
								<chakra.p
									style={{
										color: '#A0AEC0',
										fontSize: '10px',
										paddingLeft: '6px',
										textAlign: 'left',
									}}
								>
									Номер будет сформирован после создания
								</chakra.p>
							) : (
								<>
									{isEditable ? (
										<Controller
											name="number"
											control={control}
											render={({ field: { onChange, value }, fieldState: { error } }) => {
												return (
													<Input
														flex={1}
														tabIndex={1}
														isInvalid={!!error}
														onChange={onChange}
														value={value}
														name="number"
													/>
												);
											}}
										/>
									) : (
										<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
											{preformMap?.number}
										</Text>
									)}
								</>
							)}
						</Td>{' '}
						<Td w="auto">
							{isEditable ? (
								<Controller
									name="created_at"
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => {
										return (
											<DatePicker
												name="created_at"
												tabIndex={1}
												defaultValue={dayjs(value)}
												disabledDate={(day) => day.isAfter(dayjs())}
												format={DEFAULT_YEAR_FORMAT}
												style={{
													width: 'auto',
												}}
												onChange={(date) => onChange(date?.format(DEFAULT_YEAR_FORMAT))}
												status={error ? 'error' : ''}
											/>
										);
									}}
								/>
							) : preformMap?.created_at ? (
								<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
									{dayjs(preformMap?.created_at).format(DEFAULT_YEAR_FORMAT)}
								</Text>
							) : null}
						</Td>
						<Td></Td>
						<Td>
							{isEditable ? (
								<Controller
									name="fio_pds"
									control={control}
									render={({ field: { value, onChange }, fieldState: { error } }) => (
										<Select
											allowClear
											tabIndex={5}
											filterOption={(input, option) =>
												((option?.label ?? '') as string)
													.toLowerCase()
													.includes(input.toLowerCase())
											}
											showSearch
											placeholder="Выберите ФИО"
											optionFilterProp="children"
											onChange={(e) => {
												onChange(e);
												setValue(
													'fio_pds',
													e ? employee?.find((el) => el.value === Number(e))?.label : '',
												);
											}}
											value={value}
											options={employee}
											style={{
												whiteSpace: 'nowrap',
												width: '100%',
												boxShadow: error?.message ? '0 0 0 1px #fc5e6e' : '',
											}}
											className={clsx(styles['custom-select'], styles['preform-select'])}
										/>
									)}
								/>
							) : (
								<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
									{preformMap?.fio_pds}
								</Text>
							)}
						</Td>
					</Tr>
				</Tbody>
			</Table>
		</Flex>
	);
};
