import { Box, Flex, TextHeader, chakra, Input } from '@chakra-ui-kraud/react';
import { Controller, useFormContext } from 'react-hook-form';
import { forwardRef, useCallback, useEffect } from 'react';
import { MainBlockAssembly, SGDAssemblyBlock } from './blocks';
import { AssemblyConfig } from './form-assembly-config';
import styles from './tech-card.module.scss';
import { DEFAULT_YEAR_FORMAT, mainBlockId, pirntAreaBlockId } from '@/shared/constants';
import { ProductsBlock } from './blocks/products';
import { OperationsAssemblyBlock } from './blocks/operations/operations/operations-assembly';
import { useGetAllInitialsQuery } from '@/shared/state/api/swagger';
import { TechCardStatuses, useAppSelector, UserRoles, useTechCard } from '@/shared';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { TechCardAssemblyProps } from './tech-card.types';
import clsx from 'clsx';
import { useFocusedBlock } from './lib/use-focused-block';
import { selectEmployeesInitials } from '@/entities/employee';

export const TechCardAssembly = forwardRef<HTMLDivElement, TechCardAssemblyProps>(
	({ isEditable, isPrinting, focusedBlock }, ref) => {
		const { focused, handleFocus } = useFocusedBlock({ isEditable, focusedBlock });
		const { control } = useFormContext<AssemblyConfig>();

		const { options: employeeForSelect } = useGetAllInitialsQuery(
			{ stringToSearch: '' },
			{
				selectFromResult: (result) => ({
					...result,
					options: selectEmployeesInitials(result?.data),
				}),
			},
		);

		const userRole = useAppSelector((state) => state.auth.userProfile?.role);
		const { assemblyCard, isCreationMode } = useTechCard();

		const handleResize = useCallback(() => {
			const inner = document.getElementById(pirntAreaBlockId);
			const mainBLock = document.getElementById(mainBlockId);
			if (!inner) return;
			const innerBase = inner.scrollWidth;
			const width = mainBLock?.clientWidth;
			if (!width) return;

			if (width < innerBase) {
				inner.style.transform = `scale(${width / (innerBase + 24)})`;
				inner.style.transformOrigin = '0 0';
			} else {
				inner.style.transform = 'scale(1)';
				inner.style.transformOrigin = '0 0';
			}
		}, []);

		// hotfix
		// нормально не получается скейлить карту, у которой есть связи
		// TODO
		handleResize();

		useEffect(() => {
			handleResize();
			window.addEventListener('resize', handleResize);
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}, [handleResize]);

		const number = assemblyCard?.symbol
			? `${assemblyCard?.symbol}-${Number(assemblyCard?.number)}`
			: assemblyCard?.number;

		return (
			<>
				<Box ref={ref} id={pirntAreaBlockId}>
					{!isEditable || isPrinting ? (
						<Box py={'6px'} mt={6} px={6} gap={2} alignItems="center">
							<TextHeader variant="h5" fontWeight={'600'}>
								{`Сборочная маршрутная карта (партия) № ${number} от ${dayjs(
									assemblyCard?.created_at,
								).format(DEFAULT_YEAR_FORMAT)}`}
							</TextHeader>
						</Box>
					) : (
						<Flex pt={6} px={6} gap={2} alignItems="center">
							<TextHeader variant="h5" fontWeight={'600'}>
								Сборочная маршрутная карта (партия) №
							</TextHeader>
							<Flex
								borderBottom={'1px solid var(--gray-100, #EDF2F7)'}
								py={'6px'}
								px={3}
								alignItems="center"
							>
								{isCreationMode ? (
									<>
										<chakra.p w="52px" textAlign={'right'}>
											—
										</chakra.p>
										<chakra.p
											color="gray.400"
											fontSize={'10px'}
											lineHeight={'12px'}
											textAlign="end"
											pl={3}
										>
											Номер будет сформирован после создания
										</chakra.p>
									</>
								) : (
									<Flex
										textAlign={'left'}
										alignItems={'center'}
										className={clsx(styles['print_bold_font'], styles['card-number-wrapper'])}
									>
										{isEditable ? (
											<>
												<Controller
													name="symbol"
													control={control}
													render={({ field: { onChange, value }, fieldState: { error } }) => {
														return (
															<Input
																flex={1}
																tabIndex={1}
																isInvalid={!!error}
																variant={'ghost'}
																onChange={onChange}
																value={value}
																size={'xs'}
																name="symbol"
																whiteSpace="36px"
																maxWidth="36px"
																maxLength={2}
															/>
														);
													}}
												/>
												-
												<Controller
													name="number"
													control={control}
													render={({ field: { onChange, value }, fieldState: { error } }) => {
														return (
															<Input
																flex={1}
																tabIndex={1}
																size={'xs'}
																variant={'ghost'}
																isInvalid={!!error}
																onChange={onChange}
																value={value}
																name="number"
															/>
														);
													}}
												/>
											</>
										) : assemblyCard?.number && assemblyCard?.symbol ? (
											`${assemblyCard?.symbol}-${assemblyCard?.number}`
										) : (
											assemblyCard?.number
										)}
									</Flex>
								)}
							</Flex>
							<Flex gap={1} alignItems="end">
								<chakra.div alignSelf={'center'} fontSize={'sm'} color="gray.800">
									от
								</chakra.div>
								<Controller
									name="created_at"
									control={control}
									render={({ field: { onChange, value }, fieldState: { error } }) => {
										return (
											<DatePicker
												name="created_at"
												tabIndex={1}
												inputReadOnly={
													!(
														isCreationMode ||
														(isEditable &&
															assemblyCard?.status === TechCardStatuses.progress)
													)
												}
												className={styles['datepicker']}
												defaultValue={dayjs(value || new Date())}
												disabledDate={(day) => day.isAfter(dayjs())}
												format={DEFAULT_YEAR_FORMAT}
												onChange={(date) => onChange(date?.format(DEFAULT_YEAR_FORMAT))}
												status={error ? 'error' : ''}
											/>
										);
									}}
								/>
							</Flex>
						</Flex>
					)}

					<Box className={clsx(styles['tech-card'], styles['tech-card__assembly'])} maxWidth="100%">
						<Flex gap={6} alignItems="stretch" className={styles['main_flex']} direction="column">
							<Box>
								<MainBlockAssembly
									isEditable={
										isEditable &&
										(!assemblyCard ||
											[UserRoles.admin, UserRoles.senior_operator].includes(
												userRole as UserRoles,
											))
									}
									cardInfo={assemblyCard}
								/>
								<ProductsBlock isEditable={!!isEditable} />
							</Box>
						</Flex>
						<Flex flexDir="column" className={styles['operations_sgd_flex']}>
							<OperationsAssemblyBlock
								isEditable={
									isEditable &&
									!!assemblyCard &&
									(assemblyCard.status === TechCardStatuses.progress || userRole === UserRoles.admin)
								}
							/>
							<SGDAssemblyBlock
								employee={employeeForSelect || []}
								isPrinting={isPrinting}
								isEditable={
									isEditable &&
									!!assemblyCard &&
									(assemblyCard.status === TechCardStatuses.accepted_at_sgd ||
										assemblyCard.status === TechCardStatuses.partially_issued)
								}
								focusedBlock={focused}
								setFocusedBlock={focused.block === 'select_date_conservation' ? handleFocus : undefined}
							/>
						</Flex>
					</Box>
				</Box>
			</>
		);
	},
);
