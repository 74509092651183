import {
	useChangeCommentTextMutation,
	useCreateCommentForTechMapMutation,
	useDeleteCommentMutation,
	useGetProductTechMapCommentsQuery,
} from '@/shared/state/api/swagger';
import { FC } from 'react';
import { TechCardCommentsModal } from './tech-card-comments-modal';
import { TechMapProductCommentModalProps } from './tech-card-comments-modal.types';

export const TechCardProductCommentModal: FC<TechMapProductCommentModalProps> = ({ techMapId, ...modalProps }) => {
	const { data: comments, isFetching: commentsFetching } = useGetProductTechMapCommentsQuery(
		{ productTechMapId: techMapId },
		{
			selectFromResult: (result) => ({
				...result,
				data: result?.data?.map((item) => ({ ...item, techMapId: item.product_tech_map_id })) || [],
			}),
		},
	);
	const [deleteComment, { isLoading: deleteCommentLoading }] = useDeleteCommentMutation();
	const [changeComment, { isLoading: changeCommentLoading }] = useChangeCommentTextMutation();
	const [createComment, { isLoading: createCommentLoading }] = useCreateCommentForTechMapMutation();
	const onCreateComment = (text: string) =>
		createComment({ productTechMapId: techMapId, productTechMapCommentCreateRequest: { text } }).unwrap();
	const onDeleteComment = ({ commentId, techMapId }: { commentId: number; techMapId: number }) =>
		deleteComment({ productTechMapId: techMapId, productCommentId: commentId }).unwrap();
	const onChangeComment = ({ commentId, techMapId, text }: { commentId: number; techMapId: number; text: string }) =>
		changeComment({
			productTechMapId: techMapId,
			productCommentId: commentId,
			productTechMapCommentUpdateTextRequest: { text },
		}).unwrap();

	return (
		<TechCardCommentsModal
			data={comments}
			isCommentsLoading={commentsFetching}
			isCreateCommentLoading={createCommentLoading}
			onCreateComment={onCreateComment}
			onDeleteComment={onDeleteComment}
			onChangeComment={onChangeComment}
			isChangeCommentLoading={changeCommentLoading}
			isDeleteCommentLoading={deleteCommentLoading}
			techMapId={techMapId}
			{...modalProps}
		/>
	);
};
