import { GetProductTechMapCommentsApiResponse, TechMapSymbolCatalogDomain } from '@/shared/state/api/swagger';

export const selectTechMapSymbolOptions = (data: TechMapSymbolCatalogDomain[] = []) => {
	return data
		.filter((symbol) => symbol.symbol)
		.map((symbol) => ({
			value: symbol.symbol,
			label: symbol.symbol,
		}));
};

export const selectTechMapCommentsCount = (data?: GetProductTechMapCommentsApiResponse) => data?.length ?? 0;
