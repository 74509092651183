import {
	useChangeCommentTextForAssemblyTechMapMutation,
	useCreateCommentForAssemblyTechMapMutation,
	useDeleteCommentForAssemblyTechMapMutation,
	useGetAssemblyTechMapCommentsQuery,
} from '@/shared/state/api/swagger';
import { FC } from 'react';
import { TechCardCommentsModal } from './tech-card-comments-modal';
import { TechMapAssemblyCommentModalProps } from './tech-card-comments-modal.types';

export const TechCardAssemblyCommentModal: FC<TechMapAssemblyCommentModalProps> = ({ techMapId, ...modalProps }) => {
	const { data: comments, isFetching: commentsFetching } = useGetAssemblyTechMapCommentsQuery(
		{ assemblyTechMapId: techMapId },
		{
			selectFromResult: (result) => ({
				...result,
				data: result?.data?.map((item) => ({ ...item, techMapId: item.assembly_tech_map_id })) || [],
			}),
		},
	);
	const [deleteComment, { isLoading: deleteCommentLoading }] = useDeleteCommentForAssemblyTechMapMutation();
	const [changeComment, { isLoading: changeCommentLoading }] = useChangeCommentTextForAssemblyTechMapMutation();
	const [createComment, { isLoading: createCommentLoading }] = useCreateCommentForAssemblyTechMapMutation();
	const onCreateComment = (text: string) =>
		createComment({ assemblyTechMapId: techMapId, assemblyTechMapCommentCreateRequest: { text } }).unwrap();
	const onDeleteComment = ({ commentId, techMapId }: { commentId: number; techMapId: number }) =>
		deleteComment({ assemblyTechMapId: techMapId, assemblyCommentId: commentId }).unwrap();
	const onChangeComment = ({ commentId, techMapId, text }: { commentId: number; techMapId: number; text: string }) =>
		changeComment({
			assemblyTechMapId: techMapId,
			assemblyCommentId: commentId,
			assemblyTechMapCommentUpdateTextRequest: { text },
		}).unwrap();

	return (
		<TechCardCommentsModal
			data={comments}
			isCommentsLoading={commentsFetching}
			isCreateCommentLoading={createCommentLoading}
			onCreateComment={onCreateComment}
			onDeleteComment={onDeleteComment}
			onChangeComment={onChangeComment}
			isChangeCommentLoading={changeCommentLoading}
			isDeleteCommentLoading={deleteCommentLoading}
			techMapId={techMapId}
			{...modalProps}
		/>
	);
};
