import {
	IssueReportUpdateStatusToClosedRequest,
	useChangeIssueStatusToDoneMutation,
	useGetAllInitialsQuery,
	useGetIssueStuckOperationDetailsQuery,
} from '@/shared/state/api/swagger';
import { Button, ButtonGroup, useToast } from '@chakra-ui-kraud/react';
import { FC, useEffect, useRef } from 'react';
import { showErrorToast, showSuccessToast, SideModal } from '@/shared';
import { FormHandle, SolveIssueFormModalProps } from '../model/solve-issue-form.types';
import { SolveIssueDetails } from '@/entities/issue';
import { SolveIssueForm } from './solve-issue-form';
import { isFetchBaseQueryError } from '@/shared/state/api';
import { HttpStatusCode } from 'axios';

export const SolveIssueFormModal: FC<SolveIssueFormModalProps> = ({ issue, onClose, isOpen }) => {
	const { id, type, product_tech_map } = issue;
	const isStuck = type === 'stuck_operation';

	const { data: stuckIssue, isError } = useGetIssueStuckOperationDetailsQuery({ issueId: id }, { skip: !isStuck });
	const { stuck_product_operation } = stuckIssue || {};

	const toast = useToast();
	useEffect(() => {
		if (isError) {
			showErrorToast(toast, {
				description: 'Не удалось загрузить детали операции проблемы',
			});
		}
	}, [isError]);

	const {
		data: employees = [],
		isFetching: isEmployeesLoading,
		isError: isEmployeesError,
	} = useGetAllInitialsQuery({ stringToSearch: '' });

	useEffect(() => {
		if (isEmployeesError) {
			showErrorToast(toast, { description: 'Не удалось получить список пользователей' });
		}
	}, [isEmployeesError]);

	const formRef = useRef<FormHandle>(null);
	const [closeIssue, { isLoading: isSubmitLoading }] = useChangeIssueStatusToDoneMutation();
	const onSubmit = (data: IssueReportUpdateStatusToClosedRequest) => {
		closeIssue({ issueId: id, issueReportUpdateStatusToClosedRequest: data })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, {
					description: 'Проблема была переведена в статус "Решена"',
				});
				onClose();
			})
			.catch((err) => {
				if (isFetchBaseQueryError(err)) {
					if (err.status === HttpStatusCode.UnprocessableEntity) {
						showErrorToast(toast, {
							title: 'Проблема не исправлена',
							description:
								'Если проблема решена и МК уже на другой операции — вам нужно актуализировать МК, чтобы текущая операция стала другой',
						});
						return;
					}

					showErrorToast(toast, {
						description: 'При закрытии проблемы произошла ошибка',
					});
				}
			});
	};

	return (
		<SideModal
			title="Решение проблемы"
			onClose={onClose}
			isOpen={isOpen}
			footer={
				<ButtonGroup>
					<Button
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => {
							onClose();
						}}
					>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={() => {
							formRef?.current?.onSubmit();
						}}
						isDisabled={isEmployeesLoading}
						isLoading={isSubmitLoading}
					>
						Решить проблему
					</Button>
				</ButtonGroup>
			}
		>
			<SolveIssueDetails {...issue} stuckOperation={isStuck ? { ...stuck_product_operation } : undefined} />
			<SolveIssueForm
				onSubmit={onSubmit}
				operations={product_tech_map?.product_operations}
				defaultOperations={product_tech_map?.default_product_operations}
				employees={employees || []}
				ref={formRef}
			/>
		</SideModal>
	);
};
