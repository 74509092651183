import { generatePath } from 'react-router-dom';

export const MAIN_PATH = '/';
export const LOGIN_PATH = '/login';
export const TECH_CARDS_PATH = '/tech-cards';
export const TECH_CARD_PREFORM_PATH = '/tech-cards-preform';
export const TECH_CARD_INFO = '/tech-card/:number';
export const TECH_CARD_OPERATION_PATH = '/tech-card/:productTechMapId/operation';
export const TECH_CARD_NEW = '/tech-card/new';
export const TECH_CARD_ASSEMBLY_ROOT = '/tech-card-assembly';
export const TECH_CARD_ASSEMBLY_NEW = TECH_CARD_ASSEMBLY_ROOT + '/new';
export const TECH_CARD_ASSEMBLY_INFO = TECH_CARD_ASSEMBLY_ROOT + '/:number';
export const TECH_CARD_ASSEMBLY_EDIT = TECH_CARD_ASSEMBLY_INFO + '/edit';
export const TECH_CARD_PREFORM_INFO = TECH_CARD_PREFORM_PATH + '/:number';
export const TECH_CARD_PREFORM_EDIT = TECH_CARD_PREFORM_INFO + '/edit';
export const TECH_CARD_PREFORM_NEW = TECH_CARD_PREFORM_PATH + '/new';
export const TECH_CARD_EDIT = '/tech-card/:number/edit';
export const NUM_CATALOG = '/num-catalog';
export const USERS = '/admin/user';
export const USER_CREATE = '/admin/user/new';
export const USER_EDIT = '/admin/user/edit';
export const PRODUCT_CATALOG = '/product-catalog';
export const ASSEMBLY_PATH = '/assembly';
export const PREFORM_PATH = '/preform';
export const COUNTER_AGENT_PATH = '/agents';
export const EMPLOYEES = '/employees';
export const ISSUES_PATH = '/issues';

export const LINK_TO_TECH_CARD_INFO = (cardNumber: number) =>
	generatePath(TECH_CARD_INFO, { number: cardNumber.toString() });
export const LINK_TO_TECH_CARD_ASSEMBLY_INFO = (cardNumber: number) =>
	generatePath(TECH_CARD_ASSEMBLY_INFO, { number: cardNumber });
export const LINK_TO_TECH_CARD_PREFORM_INFO = (cardNumber: number) =>
	generatePath(TECH_CARD_PREFORM_INFO, { number: cardNumber });

export const LINK_TO_TECH_CARD_EDIT = (cardNumber: number) =>
	generatePath(TECH_CARD_EDIT, { number: cardNumber.toString() });

export const LINK_TO_TECH_CARD_ASSEMBLY_EDIT = (cardNumber: number) =>
	generatePath(TECH_CARD_ASSEMBLY_EDIT, { number: cardNumber });
export const LINK_TECH_CARD_PREFORM_EDIT = (cardNumber: number) =>
	generatePath(TECH_CARD_PREFORM_EDIT, { number: cardNumber });
