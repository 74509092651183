import { DEFAULT_YEAR_FORMAT } from '@/shared';
import { convertNumberToNumberStringWithDot } from '@/shared/core/utils/convert-string-to-number-string';
import { ProductOperationRequestCreate, ProductTechMapRequestCreate } from '@/shared/state/api/swagger';
import dayjs from 'dayjs';
import { FormT } from './tech-card-form.types';

export const prepareTechCardCreateBody = (data: FormT): ProductTechMapRequestCreate => {
	const { name, extra_name, symbol, material, extra_symbol } = data.product || {};
	const { file_id } = data;

	if (!material) {
		return {} as ProductTechMapRequestCreate;
	}

	return {
		symbol: data.symbol,
		product: {
			name: name ?? '',
			extra_name,
			symbol: symbol ?? '',
			product_catalog_id: data.product?.id,
			extra_symbol,
			material: {
				material_catalog_id: material.id!,
				symbol: material?.symbol,
				gost: material?.gost,
				sortament: material?.sortament,
				gost_na_sortament: material?.gost_na_sortament,
			},
			approval_card: data?.product?.approval_card ?? '',
			extra_approval_card: data?.product?.extra_approval_card ?? '',
		},
		created_at: data.created_at ? dayjs(data.created_at, DEFAULT_YEAR_FORMAT, true).format() : dayjs().format(),
		product_operations:
			data?.product_operations?.map((operation: ProductOperationRequestCreate) => ({
				...operation,
			})) ?? [],
		num: {
			num: data?.num?.num || 0,
			symbol: data?.num?.symbol,
			gost: data?.num?.gost,
			sortament: data?.num?.sortament,
			gost_na_sortament: data?.num?.gost_na_sortament,
			number_of_melt: data?.num?.number_of_melt,
			date_of_manufacture: data?.num?.date_of_manufacture ? data?.num?.date_of_manufacture : undefined,
			o_v_or_date_of_manufacture: data?.num?.o_v_or_date_of_manufacture,
			o_v: data?.num?.o_v,
			replace_decision: data?.num?.replace_decision,
			standard_consumption_type: data?.num?.standard_consumption_type || 'old',
		},
		product_plan: {
			fio: data?.product_plan?.fio,
			issuance_count: data?.product_plan?.issuance_count
				? Number(String(data?.product_plan?.issuance_count).replace(',', '.'))
				: undefined,
			list_number: data?.product_plan?.list_number,
			count: Number(data?.product_plan?.count),
			NR_kg: data.product_plan.NR_kg ? convertNumberToNumberStringWithDot(data.product_plan.NR_kg) : undefined,
			NR_meters: data.product_plan.NR_meters
				? convertNumberToNumberStringWithDot(data.product_plan.NR_meters)
				: undefined,
			NR_square_meters: data.product_plan.NR_square_meters
				? convertNumberToNumberStringWithDot(data.product_plan.NR_square_meters)
				: undefined,
			NR_grams: data.product_plan.NR_grams
				? convertNumberToNumberStringWithDot(data.product_plan.NR_grams)
				: undefined,
		},
		file_id: file_id,
	};
};
