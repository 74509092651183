import { selectExecutorsForOptions } from '@/entities/employee';
import { CalendarSinglePicker, CustomInput, CustomSelect, showErrorToast, showSuccessToast, SideModal } from '@/shared';
import {
	ProductTechMapLinkUpdateRequest,
	useGetBoundariesForUpdatingLinkTechMapQuery,
	useGetAllExecutorsQuery,
	useUpdateLinkTechMapMutation,
} from '@/shared/state/api/swagger';
import { Button, ButtonGroup, chakra, Spinner, useToast } from '@chakra-ui-kraud/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { clsx } from 'clsx';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import styles from './edit-link-operation-modal.module.scss';
import { EditLinkOperationModalProps } from './edit-link-operation-modal.types';

export const EditLinkOperationModal: FC<EditLinkOperationModalProps> = ({
	editLink,
	onClose,
	isOpen,
	refetchLinkHistory,
}) => {
	const { data: parentBoundaries, isLoading: parentBoundariesLoading } = useGetBoundariesForUpdatingLinkTechMapQuery({
		linkedProductOperationsId: Number(editLink.id),
	});
	const { options: employeeForSelect, isLoading: employeeLoading } = useGetAllExecutorsQuery(
		{ stringToSearch: '' },
		{
			selectFromResult: (result) => ({
				...result,
				options: selectExecutorsForOptions(result?.data),
			}),
		},
	);
	const [updateLink] = useUpdateLinkTechMapMutation();
	const toast = useToast();

	const validationSchema = yup.object().shape({
		responsible_employee_fio: yup.string().required('Ответственный сотрудник обязателен'),
		from_parent_created_at: yup.string().required('Дата обязательна'),
		count_gram: yup
			.number()
			.typeError('Количество грамм должно быть числом')
			.required('Количество грамм обязательно')
			.min(
				Number(parentBoundaries?.count_gram_min),
				`Введите значение больше ${parentBoundaries?.count_gram_min}`,
			)
			.max(
				Number(parentBoundaries?.count_gram_max),
				`Введите значение меньше ${parentBoundaries?.count_gram_max}`,
			),
		count_number: yup
			.number()
			.typeError('Количество штук должно быть числом')
			.required('Количество штук обязательно')
			.min(
				Number(parentBoundaries?.count_number_min),
				`Введите значение больше ${parentBoundaries?.count_number_min}`,
			)
			.max(
				Number(parentBoundaries?.count_number_max),
				`Введите значение меньше ${parentBoundaries?.count_number_max}`,
			),
	});

	const {
		control,
		formState: { isValid },
		handleSubmit,
	} = useForm<ProductTechMapLinkUpdateRequest>({
		resolver: yupResolver(validationSchema),
		mode: 'onBlur',
		defaultValues: {
			id: editLink?.id,
			from_parent_created_at: editLink?.date,
			responsible_employee_fio: editLink?.responsible_employee_fio,
			count_gram: editLink?.count_gram || 0,
			count_number: editLink?.count_number || 0,
		},
	});

	const onSubmit = (productTechMapLinkUpdateRequest: ProductTechMapLinkUpdateRequest) => {
		updateLink({ productTechMapLinkUpdateRequest })
			.unwrap()
			.then(() => {
				onClose();
				refetchLinkHistory();
				showSuccessToast(toast, { description: 'Связь изменена' });
			})
			.catch(() => showErrorToast(toast, { description: 'При изменении связи произошла ошибка' }));
	};

	return (
		<SideModal
			isOpen={isOpen}
			onClose={onClose}
			title="Редактирование связи"
			maxWidth="660px"
			header={null}
			overlayDisabled
			footer={
				<ButtonGroup>
					<Button
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => {
							onClose();
						}}
					>
						Отменить
					</Button>

					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={handleSubmit(onSubmit)}
						isDisabled={!isValid}
					>
						Сохранить
					</Button>
				</ButtonGroup>
			}
		>
			{!parentBoundariesLoading && !employeeLoading ? (
				<form className={clsx(styles['form'])} onSubmit={handleSubmit(onSubmit)}>
					<Controller
						control={control}
						// фио ответственного
						name="responsible_employee_fio"
						render={({ field: { onChange, value, ...field }, fieldState: { error } }) => (
							<CustomSelect
								defaultValue={value}
								label="Выберите ответственного"
								options={employeeForSelect}
								isInvalid={!!error}
								showSearch={true}
								{...field}
								onChange={(e) => {
									onChange(e);
								}}
							/>
						)}
					/>
					<Controller
						control={control}
						// дата
						name="from_parent_created_at"
						render={({ field: { onChange, value } }) => (
							<CalendarSinglePicker
								onSelectDate={(date) => {
									onChange(date);
								}}
								defaultDate={value}
								label={'Дата'}
							/>
						)}
					/>
					<chakra.div display="flex" justifyContent="space-between" gap="12px">
						<Controller
							control={control}
							// кол-во грамм
							name="count_gram"
							rules={{ required: false, value: 0 }}
							render={({ field: { onChange, ...field }, fieldState: { error } }) => (
								<CustomInput
									showTooltip={!!error}
									arrowTooltip
									tooltipContent={error?.message}
									size="md"
									{...field}
									isInvalid={!!error}
									label="Количество грамм"
									defaultValue={field.value}
									changeLabelColor={true}
									bottomDescription={
										<>
											<p>Максимум {parentBoundaries?.count_gram_max ?? '-'} грамм</p>
											<p>Минимум {parentBoundaries?.count_gram_min ?? '-'} грамм</p>
										</>
									}
									onChange={(e) => {
										onChange(Number(e.target.value.replace(/\D/g, '')));
									}}
								/>
							)}
						/>
						<Controller
							control={control}
							// кол-во штук
							name="count_number"
							render={({ field: { onChange, ...field }, fieldState: { error } }) => (
								<CustomInput
									size="md"
									{...field}
									tooltipContent={error?.message}
									showTooltip={!!error}
									isInvalid={!!error}
									label="Количество штук"
									defaultValue={field.value}
									changeLabelColor={true}
									bottomDescription={
										<>
											<p>Максимум {parentBoundaries?.count_number_max ?? '-'} штук</p>
											<p>Минимум {parentBoundaries?.count_number_min ?? '-'} штук</p>
										</>
									}
									onChange={(e) => {
										onChange(e.target.value.replace(/\D/g, ''));
									}}
								/>
							)}
						/>
					</chakra.div>
				</form>
			) : (
				<chakra.div display="flex" justifyContent="center" alignItems="center" width="100%">
					<Spinner />
				</chakra.div>
			)}
		</SideModal>
	);
};
