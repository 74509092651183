import { FC } from 'react';
import type { TechCardActionsProps } from './tech-card-actions.types';

import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	Button,
	ButtonGroup,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Portal,
	Tooltip,
	useToast,
} from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';

import {
	LINK_TO_TECH_CARD_ASSEMBLY_EDIT,
	LINK_TO_TECH_CARD_EDIT,
	LINK_TECH_CARD_PREFORM_EDIT,
	TECH_CARDS_PATH,
} from '@/shared/core';
import { TechCardStatuses, UserRoles } from '@/shared/core';
import { useAppSelector } from '@/shared/state';
import { CanceledStatusModal } from '@/entities/tables/tech-card';
import {
	generatedApi,
	ProductTechMapResponse,
	useCancelAssemblyTechMapMutation,
	useCancelPreformTechMapMutation,
	useCancelTechmapMutation,
	useDeleteAssemblyTechMapMutation,
	useDeletePreformTechMapMutation,
	useDeleteTechmapMutation,
} from '@/shared/state/api/swagger';

import { showErrorToast, showSuccessToast } from '../../shared/components/toasts';

import { DeleteModal } from '@/shared';

import styles from './tech-card-actions.module.scss';
import { CommentIconButton } from '@/entities/tech-map';
import { TechCardAssemblyCommentModal, TechCardProductCommentModal } from '../tech-card-comments-modal';
import clsx from 'clsx';

export const TechCardActions: FC<TechCardActionsProps> = ({
	status,
	value,
	onExcel,
	type,
	typeTabs,
	activeMenuItem,
	setActiveMenuItem,
	itemId,
}) => {
	const navigate = useNavigate();
	const toast = useToast();
	const dispatch = useDispatch();

	const userRole = useAppSelector((state) => state.auth.userProfile?.role);

	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const [isResetMode, setIsResetMode] = useState(false);

	const [cancelTechMap] = useCancelTechmapMutation();
	const [deleteTechMap] = useDeleteTechmapMutation();
	const [cancelAssemblyTechMapMutation] = useCancelAssemblyTechMapMutation();
	const [deleteAssemblyTechMap] = useDeleteAssemblyTechMapMutation();

	const [deletePreformTechmap] = useDeletePreformTechMapMutation();
	const handleDeletePreformTechCard = useCallback(() => {
		deletePreformTechmap({ preformTechMapId: Number(value?.id) })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: `Заготовительная МК № ${value?.number} удалена` });
				navigate(TECH_CARDS_PATH);
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При удалении заготовительной МК произошла ошибка' });
			});
		setDeleteModalOpen(false);
	}, [navigate, toast]);

	const [cancelPreformTechMapMutation] = useCancelPreformTechMapMutation();
	const cancelPreformTechMap = useCallback(() => {
		cancelPreformTechMapMutation({ preformTechMapId: Number(value?.id) })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: `Карта № ${value?.number} аннулирована` });
			})
			.catch(() => showErrorToast(toast, { description: 'При аннулировании МК произошла ошибка' }));
	}, [cancelPreformTechMapMutation, toast, value?.id, value?.number]);

	const handleDeleteAssemblyTechCard = useCallback(() => {
		deleteAssemblyTechMap({ assemblyTechMapId: Number(value?.id) })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: `МК № ${value?.number} удалена` });
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При удалении МК произошла ошибка' });
			});
		setDeleteModalOpen(false);
	}, [deleteAssemblyTechMap, navigate, toast, value?.number]);

	const cancelAssemblyTechMap = useCallback(() => {
		cancelAssemblyTechMapMutation({ assemblyTechMapId: Number(value?.id) })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: `Карта № ${value.number} аннулирована` });
			})
			.catch(() => showErrorToast(toast, { description: 'При аннулировании МК произошла ошибка' }));
	}, [cancelAssemblyTechMapMutation, value?.id, value.number, toast, dispatch]);

	const handleDeleteTechCard = () => {
		deleteTechMap({ productTechMapNumber: Number(value?.number) })
			.unwrap()
			.then(() => {
				showSuccessToast(toast, { description: `МК № ${value?.number} удалена` });
				// @ts-ignore TODO: https://github.com/reduxjs/redux-toolkit/issues/1510
				dispatch(generatedApi.util.invalidateTags(['Product_tech_map endpoints']));
			})
			.catch(() => {
				showErrorToast(toast, { description: 'При удалении МК произошла ошибка' });
			});
		setDeleteModalOpen(false);
	};

	const changeStatusToCanceled = () => {
		value.id &&
			cancelTechMap({
				productTechMapChangeStatusAdminRequest: {
					status: 'canceled',
					product_tech_map_id: value.id,
				},
			})
				.unwrap()
				.then(() => {
					// @ts-ignore TODO: https://github.com/reduxjs/redux-toolkit/issues/1510
					dispatch(generatedApi.util.invalidateTags(['Product_tech_map endpoints']));
					showSuccessToast(toast, { description: `Карта №${value.number} аннулирована` });
				})
				.catch(() => showErrorToast(toast, { description: 'При аннулировании МК произошла ошибка' }));

		setIsResetMode(false);
	};

	const allTypeField = 'type' in value ? value?.type?.split('_')[0] || '' : '';

	const cancelActions: Record<string, () => void> = {
		preform: cancelPreformTechMap,
		product: changeStatusToCanceled,
		assembly: cancelAssemblyTechMap,
		get all() {
			return this[allTypeField];
		},
	};

	const deleteActions: Record<string, () => void> = {
		preform: handleDeletePreformTechCard,
		product: handleDeleteTechCard,
		assembly: handleDeleteAssemblyTechCard,
		get all() {
			return this[allTypeField];
		},
	};

	const navigateLinks: Record<string, (cardNumber: number) => string> = {
		preform: LINK_TECH_CARD_PREFORM_EDIT,
		product: LINK_TO_TECH_CARD_EDIT,
		assembly: LINK_TO_TECH_CARD_ASSEMBLY_EDIT,
		get all() {
			return this[allTypeField];
		},
	};

	const sgdOutLinks: Record<string, () => void> = {
		product: () =>
			navigate(LINK_TO_TECH_CARD_EDIT(value.number!), {
				state: { focusedBlock: 'select_date_conservation' },
			}),
		preform: () =>
			navigate(LINK_TECH_CARD_PREFORM_EDIT(value.number!), {
				state: { focusedBlock: 'select_date_conservation' },
			}),
		assembly: () =>
			navigate(LINK_TO_TECH_CARD_ASSEMBLY_EDIT(value.number!), {
				state: { focusedBlock: 'select_date_conservation' },
			}),
		get all() {
			return this[allTypeField];
		},
	};

	const [commentsModalOpen, setCommentsModalOpen] = useState(false);
	const commentsTitle =
		typeTabs === 'all'
			? value?.number
			: value?.number && 'symbol' in value && value?.symbol
			? `${value?.symbol}-${value?.number}`
			: value?.number;

	const isPreformTechMap = type === 'preform' || allTypeField === 'preform';
	const isAssemblyTechMap = type === 'assembly' || allTypeField === 'assembly';

	const countComments = 'comments_count' in value ? value?.comments_count ?? 0 : 0;

	return (
		<>
			<ButtonGroup className={styles['menu']}>
				<Tooltip label="Редактировать МК" hasArrow placement="bottom">
					<IconButton
						aria-label=""
						icon={
							<MadIcon module="basic" name="pencil-square" type="outline" mode="default" size="default" />
						}
						fontSize={'14px'}
						variant="ghost"
						colorScheme="tertiary"
						onClick={() => value.number && navigate(navigateLinks[type](value.number))}
						className={styles['menu-icon']}
						_focus={{ boxShadow: 'none' }}
					/>
				</Tooltip>
				<CommentIconButton
					count={countComments}
					aria-label=""
					hidden={isPreformTechMap}
					className={clsx(countComments === 0 && styles['menu-icon'])}
					onClick={() => setCommentsModalOpen(true)}
				/>
				{userRole !== UserRoles.user && (
					<>
						<Menu isOpen={activeMenuItem === itemId} closeOnBlur closeOnSelect>
							<MenuButton
								as={IconButton}
								aria-label=""
								icon={
									<MadIcon
										module="basic"
										type="outline"
										mode="default"
										size="default"
										name="ellipsis-vertical"
									/>
								}
								className={styles['menu-button']}
								size="xs"
								variant="unstyled"
								colorScheme="tertiary"
								onClick={() => setActiveMenuItem(itemId)}
								style={{ marginInlineStart: '0', WebkitMarginStart: '0' }}
								_focus={{ boxShadow: 'none' }}
							/>
							<Portal>
								<MenuList
									p="12px"
									borderRadius="12px"
									border="none"
									minW="none"
									zIndex={1500}
									onMouseLeave={() => setActiveMenuItem(null)}
									onMouseUp={() => setActiveMenuItem(null)}
								>
									{(status === TechCardStatuses.accepted_at_sgd ||
										status === TechCardStatuses.partially_issued ||
										status === TechCardStatuses.accepted_at_storage) && (
										<>
											<MenuItem
												as={Button}
												size="sm"
												variant="ghost"
												fontFamily="Raleway"
												colorScheme="tertiary"
												justifyContent="flex-start"
												onClick={() => sgdOutLinks[type]()}
												_focus={{ boxShadow: 'none' }}
												hidden={isPreformTechMap}
											>
												Выдать...
											</MenuItem>
											<MenuDivider opacity="0.1" hidden={isPreformTechMap} />
										</>
									)}

									{(userRole === UserRoles.admin || userRole === UserRoles.senior_operator) &&
										status !== TechCardStatuses.canceled && (
											<>
												<MenuItem
													as={Button}
													size="sm"
													variant="ghost"
													fontFamily="Raleway"
													colorScheme="tertiary"
													justifyContent="flex-start"
													onClick={() => setIsResetMode(true)}
													_focus={{ boxShadow: 'none' }}
												>
													Аннулировать
												</MenuItem>
											</>
										)}
									{userRole === UserRoles.admin && (
										<>
											<MenuItem
												as={Button}
												size="sm"
												variant="ghost"
												fontFamily="Raleway"
												colorScheme="danger"
												justifyContent="flex-start"
												color="red"
												onClick={() => setDeleteModalOpen(true)}
											>
												Удалить карту
											</MenuItem>
											<MenuDivider hidden={isPreformTechMap || isAssemblyTechMap} opacity="0.1" />
										</>
									)}

									<MenuItem
										as={Button}
										size="sm"
										hidden={isPreformTechMap || isAssemblyTechMap}
										variant="ghost"
										fontFamily="Raleway"
										colorScheme="tertiary"
										justifyContent="flex-start"
										onClick={() => onExcel(value as ProductTechMapResponse)}
										_focus={{ boxShadow: 'none' }}
									>
										Скачать в Excel
									</MenuItem>
								</MenuList>
							</Portal>
						</Menu>
					</>
				)}
			</ButtonGroup>
			<Portal>
				<CanceledStatusModal
					isOpen={isResetMode}
					onClose={() => setIsResetMode(false)}
					onCancelTechMap={cancelActions[type]}
				/>
			</Portal>
			<Portal>
				<DeleteModal
					title="Удаление МК"
					text="Вы уверены, что хотите удалить МК?"
					isOpen={deleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
					onDelete={() => deleteActions[type]()}
				/>
			</Portal>
			<Portal>
				{commentsModalOpen &&
					(type === 'product' ? (
						<TechCardProductCommentModal
							title={`Комментарии к МК ${commentsTitle}`}
							isOpen={commentsModalOpen}
							onClose={() => setCommentsModalOpen(false)}
							techMapId={Number(value?.id)}
						/>
					) : (
						<TechCardAssemblyCommentModal
							title={`Комментарии к МК ${commentsTitle}`}
							isOpen={commentsModalOpen}
							onClose={() => setCommentsModalOpen(false)}
							techMapId={Number(value?.id)}
						/>
					))}
			</Portal>
		</>
	);
};
