import { TECH_MAP_ADDITIONAL_FILE_EXTENSIONS } from '@/entities/tech-map';
import {
	DeleteModal,
	handleDownloadFile,
	showErrorToast,
	showSuccessToast,
	TechCardStatuses,
	TECH_CARDS_PATH,
	useTechCard,
} from '@/shared';
import {
	useChangePreformTechMapFileMutation,
	useDeletePreformTechMapMutation,
	useGetPreformTechMapByNumberQuery,
	useLazyGetFileQuery,
} from '@/shared/state/api/swagger';
import {
	Button,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Portal,
	Tooltip,
	useToast,
} from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionTechCardPreformMenuProps } from './action-tech-card-menu.types';

export const ActionsTechCardPreformMenu: FC<ActionTechCardPreformMenuProps> = ({ setIsResetMode }) => {
	const [deleteTechmap] = useDeletePreformTechMapMutation();
	const toast = useToast();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const navigate = useNavigate();
	const mapNumber = Number(useParams().number);

	const { userRole } = useTechCard();
	const { data: preformMap, refetch } = useGetPreformTechMapByNumberQuery(
		{ preformTechMapNumber: mapNumber },
		{
			skip: !mapNumber || Number.isNaN(mapNumber),
		},
	);

	const hideCancelButton = useMemo(() => {
		// если роль пользователя "Пользователь" или "Оператор" - скрываем кнопку в любой карте
		if (userRole && ['operator', 'user'].includes(userRole)) {
			return true;
		}

		// если роль пользователя - "Админ" или "Старший оператор", и статус текущей карты "аннулирована" - скрываем кнопку, в остальных случаях можно аннулировать карту
		if (preformMap?.status && userRole && !['operator', 'user'].includes(userRole)) {
			if (preformMap.status === TechCardStatuses.canceled) {
				return true;
			}
		}

		return false;
	}, [preformMap?.status, userRole]);

	const [getAdditionalFile] = useLazyGetFileQuery();
	const [changeTechMapFile, { isLoading: isFileUploadLoading }] = useChangePreformTechMapFileMutation();
	const { open: handleUploadFile } = useDropzone({
		maxFiles: 1,
		accept: TECH_MAP_ADDITIONAL_FILE_EXTENSIONS,
		onDrop(acceptedFiles) {
			if (!preformMap?.id) return;
			changeTechMapFile({
				preformTechMapId: preformMap?.id,
				bodyChangePreformTechMapFile: { file: acceptedFiles[0] },
			})
				.unwrap()
				.then(() => {
					refetch().then(() => {
						showSuccessToast(toast, { description: 'Файл успешно загружен' });
					});
				})
				.catch(() => {
					showErrorToast(toast, { description: 'При загрузке файла произошла ошибка' });
				});
		},
	});
	const handleGetAdditionalFile = useCallback(() => {
		const id = preformMap?.file?.id;
		if (!id) return;
		getAdditionalFile({ id: String(id) })
			.unwrap()
			.then((res) => handleDownloadFile(res as unknown as Blob, String(preformMap?.file?.filename)))
			.catch(() => {
				showErrorToast(toast, { description: 'При скачивании файла произошла ошибка' });
			});
	}, [preformMap]);

	const handleDeleteTechCard = useCallback(
		(preformTechMapNumber: number) => {
			deleteTechmap({ preformTechMapId: preformTechMapNumber })
				.unwrap()
				.then(() => {
					showSuccessToast(toast, { description: `Заготовительная МК № ${preformMap?.number} удалена` });
					navigate(TECH_CARDS_PATH);
				})
				.catch(() => {
					showErrorToast(toast, { description: 'При удалении заготовительной МК произошла ошибка' });
				});
			setDeleteModalOpen(false);
		},
		[preformMap?.number, deleteTechmap, navigate, toast],
	);

	return (
		<>
			<Menu>
				<MenuButton
					as={IconButton}
					size="md"
					variant="ghost"
					colorScheme="tertiary"
					icon={
						<MadIcon module="basic" mode="default" size="default" type="outline" name="ellipsis-vertical" />
					}
				/>
				<MenuList p="10px">
					<Tooltip isDisabled={!!preformMap?.file} hasArrow label="У этой МК нет файла">
						<MenuItem
							closeOnSelect={!!preformMap?.file}
							isDisabled={!preformMap?.file}
							style={{
								opacity: preformMap?.file ? 1 : 0.5,
								pointerEvents: preformMap?.file ? 'all' : 'none',
							}}
							as={Button}
							leftIcon={
								<MadIcon
									module="basic"
									mode="default"
									size="default"
									type="outline"
									name="arrow-down-tray"
								/>
							}
							size="sm"
							variant="ghost"
							colorScheme="tertiary"
							onClick={handleGetAdditionalFile}
							justifyContent="flex-start"
						>
							Скачать приложения к МК
						</MenuItem>
					</Tooltip>
					<MenuItem
						as={Button}
						leftIcon={
							<MadIcon module="basic" mode="default" size="default" type="outline" name="arrow-up-tray" />
						}
						size="sm"
						variant="ghost"
						colorScheme="tertiary"
						onClick={handleUploadFile}
						disabled={isFileUploadLoading}
						justifyContent="flex-start"
					>
						Загрузить приложения к МК
					</MenuItem>
					{(!hideCancelButton || userRole === 'admin') && <MenuDivider />}
					{!hideCancelButton && (
						<MenuItem
							as={Button}
							leftIcon={
								<MadIcon module="basic" mode="default" size="default" type="outline" name="x-circle" />
							}
							size="sm"
							variant="ghost"
							colorScheme="tertiary"
							onClick={() => setIsResetMode(true)}
							justifyContent="flex-start"
						>
							Аннулировать
						</MenuItem>
					)}
					{userRole === 'admin' && (
						<MenuItem
							as={Button}
							leftIcon={
								<MadIcon module="basic" mode="default" size="default" type="outline" name="trashcan" />
							}
							size="sm"
							variant="ghost"
							colorScheme="danger"
							onClick={() => setDeleteModalOpen(true)}
							justifyContent="flex-start"
							color="red"
						>
							Удалить карту
						</MenuItem>
					)}
				</MenuList>
			</Menu>
			<Portal>
				<DeleteModal
					title="Удаление заготовительную МК"
					text="Вы уверены, что хотите удалить заготовительную МК?"
					isOpen={deleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
					onDelete={() => handleDeleteTechCard(Number(preformMap?.id))}
				/>
			</Portal>
		</>
	);
};
