import { CancelOperationAlert, ErrorFillOperationAlert, OperationUnfilled, SkippedOperationsAlert } from '@/entities';
import { CalendarSinglePicker, CustomSelect, useAppSelector } from '@/shared';
import styles from '@/shared/components/custom-select/custom-select.module.scss';
import { CancelProductOperationRequest, useCancelOperationMutation } from '@/shared/state/api/swagger';
import { selectCurrentUserId } from '@/shared/state/slices';
import { useOperationNotification, useUpdateOperations } from '@/widgets/operation/hooks';
import { cancelOperationSchema } from '@/widgets/operation/model/operations.schema';
import { Button, chakra, Text } from '@chakra-ui-kraud/react';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { OperationLayout } from '../../operation-layout/operation-layout';
import { OperationFormHeader } from '../form-header/form-header';
import { CancelOperationFormProps } from './cancel-operation-form.types';

type CancelOperationFormValues = Omit<CancelProductOperationRequest, 'closing_employee_fio'> & {
	closing_employee_fio: string | number;
	date: string;
};

export const CancelOperationForm = ({
	skippedOperations,
	setSkippedOperations,
	techMap,
	mappedEmployees,
}: CancelOperationFormProps) => {
	const [step, setStep] = useState(0);
	const [preview, setPreview] = useState(true);
	const [error, setError] = useState(false);

	const operation = skippedOperations[step];

	const [cancelOperation] = useCancelOperationMutation();
	const { updateOperations } = useUpdateOperations(Number(techMap.id));
	const [groupVisibility, setGroupVisibility] = useState(false);
	const currentUserId = useAppSelector(selectCurrentUserId);

	const { showOperationToast } = useOperationNotification();

	const [isFetching, setIsFetching] = useState(false);

	const onSubmit = (data: CancelOperationFormValues) => {
		if (!operation.id) return;

		const payload: CancelProductOperationRequest = {
			closing_employee_fio:
				mappedEmployees.find(
					(employee) => Number(employee.value.split('_')[0]) === Number(data.closing_employee_fio),
				)?.label ?? '',
			product_operation_id: operation.id,
		};

		let operationsForSkip = skippedOperations.map((op) =>
			op.id === operation.id ? { ...op, date: data.date, is_canceled: true } : op,
		);

		setIsFetching(true);

		cancelOperation({ cancelProductOperationRequest: payload })
			.unwrap()
			.then((data) => {
				if (!data) throw new Error('Operation not canceled');

				return operationsForSkip;
			})
			.catch((err) => {
				console.error(err);

				// Если не удалось отенить операцию, помечаем ее как не отмененную
				operationsForSkip = skippedOperations.map((op) =>
					op.id === operation.id ? { ...op, date: data.date, is_canceled: false } : op,
				);

				return operationsForSkip;
			})
			.then((operationsForSkip) => {
				// Если это последний шаг, то отправляем операции на обновление
				if (step >= skippedOperations.length - 1) {
					return updateOperations(operationsForSkip).then(() => operationsForSkip);
				} else {
					showOperationToast({
						status: 'success',
						type: 'cancel',
						stepId: operation?.step_id,
						name: String(operation?.name),
					});
				}

				return operationsForSkip;
			})
			.then((operationsForSkip) => {
				setStep(step + 1);
				setSkippedOperations(operationsForSkip);
			})
			.catch((err) => {
				setError(false);
				console.error(err);
			})
			.finally(() => {
				setIsFetching(false);
			});
	};

	const onSkipOperation = () => {
		if (step === skippedOperations.length - 1) {
			updateOperations(skippedOperations);
			setStep(step + 1);
		} else {
			showOperationToast({
				status: 'success',
				type: 'skip',
				stepId: operation?.step_id,
				name: String(operation?.name),
			});
			setStep(step + 1);
		}
	};

	const { control, handleSubmit } = useForm<CancelOperationFormValues>({
		defaultValues: {
			closing_employee_fio:
				mappedEmployees.find((employee) => Number(employee.value.split('_')[0]) === currentUserId)?.value ??
				undefined,
			product_operation_id: operation?.id,
		},
		resolver: yupResolver(cancelOperationSchema),
	});

	if (step === skippedOperations.length) {
		const lastOperation = skippedOperations[skippedOperations.length - 1];
		return lastOperation.is_canceled ? (
			<CancelOperationAlert stepId={lastOperation?.step_id} operationName={lastOperation?.name} />
		) : (
			<OperationUnfilled stepId={lastOperation?.step_id} operationName={lastOperation?.name} />
		);
	}

	if (error) {
		return (
			<ErrorFillOperationAlert
				handleCompleteOperationAgain={() => setError(false)}
				productTechMapNumber={techMap?.number}
			/>
		);
	}

	if (preview) {
		return (
			<SkippedOperationsAlert
				handleSkipOperations={() => {
					setSkippedOperations([]);
					setPreview(false);
				}}
				handleCancelOperations={() => setPreview(false)}
			/>
		);
	}
	return (
		<OperationLayout
			header={<OperationFormHeader title="Отмена операции" techMap={techMap} />}
			footer={
				<chakra.div display="flex" flexDirection="column" gap="12px" w="100%">
					<Button variant="solid" width="100%" onClick={handleSubmit(onSubmit)} isDisabled={isFetching}>
						Отменить операцию
					</Button>
					<Button
						colorScheme="tertiary"
						variant="outline"
						width="100%"
						onClick={onSkipOperation}
						isDisabled={isFetching}
					>
						Оставить незаполненной
					</Button>
				</chakra.div>
			}
		>
			<chakra.div display="flex" flexDirection="column" gap="16px">
				<Text color="gray.800" fontSize="16px" fontWeight="400" lineHeight="24px">
					Вы пропустили операцию{' '}
					<strong>
						{operation?.step_id} — {operation?.name}
					</strong>
					. Для отмены выберите ответственного или заполните операцию позднее.
				</Text>
				<Controller
					name="date"
					control={control}
					render={({ field: { onChange } }) => (
						<CalendarSinglePicker
							clearable={false}
							maxDate={new Date(2050, 1)}
							defaultDate={dayjs().format('YYYY-MM-DD')}
							label="Дата упаковки и консервации"
							onSelectDate={(date) => onChange(date)}
						/>
					)}
				/>
				<Controller
					name="closing_employee_fio"
					control={control}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<CustomSelect
							onFocus={() => setGroupVisibility(true)}
							onBlur={() => setGroupVisibility(false)}
							showSearch
							allowClear={false}
							options={mappedEmployees}
							value={value}
							onChange={(e) => onChange(e)}
							filterOption={(input, option) =>
								String(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							defaultValue={
								mappedEmployees.find((employee) => employee.value === value)?.label ?? undefined
							}
							label="Исполнитель"
							optionFilterProp="children"
							dropdownStyle={{ zIndex: 1400 }}
							size="large"
							className={clsx(
								styles['select'],
								styles['select-large'],
								groupVisibility && styles['select-focus'],
								error && [styles['select-invalid']],
							)}
						/>
					)}
				/>
			</chakra.div>
		</OperationLayout>
	);
};
