import type { FC } from 'react';

import { useCallback, useEffect, useMemo } from 'react';
import { Button, ButtonGroup, useToast } from '@chakra-ui-kraud/react';
import { FormProvider, useForm } from 'react-hook-form';

import { showErrorToast, showSuccessToast, SideModal } from '@/shared';
import {
	useAddFileToPreformMutation,
	useCreatePreformMutation,
	useUpdatePreformMutation,
} from '@/shared/state/api/swagger';
import { yupResolver } from '@hookform/resolvers/yup';
import { PreformModalProps } from '../model/interface/preform-modal.types';
import { PreformForm } from '@/entities/forms/preform-form';
import { IPreformFormData } from '@/entities/forms/preform-form';
import { preformCreateValidation } from '@/entities/forms/preform-form/validations/preform-validation';

export const PreformModal: FC<PreformModalProps> = ({ isOpen, onClose, variant, selectedPreform }) => {
	const toast = useToast();
	const [addFileToPreform, { isLoading: isFileUpdateLoading }] = useAddFileToPreformMutation();

	const defaultValues = useMemo<IPreformFormData>(() => {
		return {
			file: selectedPreform?.file,
			name: selectedPreform?.name ?? '',
			symbol: '',
			operation_types: selectedPreform?.operation_types?.length
				? selectedPreform.operation_types.map((operation: any, idx: number) => ({
						step_id: operation.step_id ?? (idx + 1) * 5,
						name: operation.name ?? '',
						id: operation.operation_type_id ?? idx * -1,
				  }))
				: [
						{
							step_id: 5,
							name: '',
							id: -1,
						},
				  ],
		};
	}, [selectedPreform]);

	const form = useForm<IPreformFormData>({
		defaultValues,
		resolver: yupResolver(preformCreateValidation),
	});

	const [createPreform, { isLoading: isCreateLoading }] = useCreatePreformMutation();
	const [updatePreform, { isLoading: isUpdateLoading }] = useUpdatePreformMutation();
	const isLoading = isCreateLoading || isUpdateLoading || isFileUpdateLoading;

	const onSubmit = useCallback(
		(data: IPreformFormData) => {
			const formattedData = {
				...data,
				operation_types: data.operation_types.map((operation) => ({
					name: operation.name,
					step_id: operation.step_id,
				})),
			};

			if (variant === 'create') {
				createPreform({
					preformCatalogCreateRequest: formattedData,
				})
					.unwrap()
					.then((preform) => {
						if (data?.file && data?.file instanceof Blob && preform?.id) {
							return addFileToPreform({
								bodyAddFileToPreform: { file: data.file },
								preformId: preform?.id,
							});
						}
					})
					.then(() => {
						showSuccessToast(toast, { description: 'Процесс заготовки создан' });
						onClose();
						form.reset();
					})
					.catch((err) => {
						console.error(err);
						showErrorToast(toast, { title: 'При создании процесса заготовки произошла ошибка' });
					});
			} else if (variant === 'edit' && selectedPreform) {
				updatePreform({ preformId: selectedPreform.id, preformCatalogUpdateRequest: formattedData })
					.unwrap()
					.then((preform) => {
						if (data?.file && data?.file instanceof Blob && preform?.id) {
							return addFileToPreform({
								bodyAddFileToPreform: { file: data.file },
								preformId: preform?.id,
							});
						}
					})
					.then(() => {
						showSuccessToast(toast, { description: 'Процесс заготовки обновлен' });
						onClose();
						form.reset();
					})
					.catch((err) => {
						console.error(err);
						showErrorToast(toast, { title: 'При обновлении процесса заготовки произошла ошибка' });
					});
			}
		},
		[variant, selectedPreform],
	);

	useEffect(() => {
		form.reset(defaultValues);
	}, [variant, selectedPreform, isOpen]);

	return (
		<SideModal
			title={variant === 'edit' ? 'Редактирование процесса заготовки' : 'Добавление процесса заготовки'}
			isOpen={isOpen}
			onClose={onClose}
			maxWidth={'644px'}
			footer={
				<ButtonGroup>
					<Button size="md" variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={form.handleSubmit(onSubmit)}
						isLoading={isLoading}
					>
						{variant === 'edit' ? 'Сохранить' : 'Добавить процесс заготовки'}
					</Button>
				</ButtonGroup>
			}
			onBlur={onClose}
		>
			<FormProvider {...form}>
				<PreformForm />
			</FormProvider>
		</SideModal>
	);
};
