import { SideModal } from '@/shared/components';
import { GetIssuesApiArg, useGetAllInitialsQuery } from '@/shared/state/api/swagger';
import { Button, ButtonGroup } from '@chakra-ui-kraud/react';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { FilterModalProps } from './issue-filter-modal.types';
import { OpenAtFrom, ProductSymbol, Statuses, Users } from './ui/filters';

export const IssueFilterModal: FC<FilterModalProps> = ({ isOpen, onClose, onSubmit, defaultValues }) => {
	const { control, handleSubmit, setValue } = useForm<GetIssuesApiArg>({
		defaultValues,
	});

	const resetForm = () => {
		setValue('productSymbol', undefined);
		setValue('status', undefined);
		setValue('openAtFrom', undefined);
		setValue('openAtTo', undefined);
		setValue('executorUserId', undefined);
		setValue('responsibleUserId', undefined);

		handleSubmit(onSubmit)();
	};

	const { data: employees = [] } = useGetAllInitialsQuery({ stringToSearch: ' ' });

	const mappedEmployees = employees.map(({ id, initials }) => ({
		label: initials,
		value: id,
	}));

	return (
		<SideModal
			isOpen={isOpen}
			onClose={onClose}
			title="Фильтры"
			footer={
				<ButtonGroup>
					<Button size="md" variant="ghost" colorScheme="tertiary" type="button" onClick={resetForm}>
						Сбросить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						type="submit"
						onClick={handleSubmit(onSubmit)}
					>
						Применить
					</Button>
				</ButtonGroup>
			}
			onBlur={onClose}
		>
			<form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
				<Statuses control={control} />
				<ProductSymbol control={control} />
				<OpenAtFrom control={control} />
				<Users
					key="executor"
					control={control}
					label="Исполнитель"
					mappedEmployees={mappedEmployees}
					name="executorUserId"
				/>
				<Users
					key="responsible"
					control={control}
					label="Ответственный"
					mappedEmployees={mappedEmployees}
					name="responsibleUserId"
				/>
			</form>
		</SideModal>
	);
};
