import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { FormProvider } from 'react-hook-form';

import { Box, Button, ButtonGroup, IconButton, TextHeader, Tooltip, useToast } from '@chakra-ui-kraud/react';
import { ArrowBackIcon, ChevronRightIcon } from '@chakra-ui/icons';

import { Layout, TechCardAssemblyCommentModal } from '@/widgets';
import styles from './tech-card-page.module.scss';
import { useAssemblyFormConfig } from '@/widgets/tech-card/form-assembly-config';
import { TechCardAssembly } from '@/widgets/tech-card';
import {
	AssemblyTechMapCreateRequest,
	AssemblyTechMapResponse,
	useCreateAssemblyTechMapMutation,
	useGetAssemblyTechMapCommentsQuery,
	useLazyGetFileQuery,
} from '@/shared/state/api/swagger';
import {
	LINK_TO_TECH_CARD_ASSEMBLY_INFO,
	Loader,
	showErrorToast,
	MainContentLoader,
	showSuccessToast,
	TechCardStatusColors,
	TechCardStatuses,
	TECH_CARDS_PATH,
	useTechCard,
} from '@/shared';
import { CancelModal } from './cancel-modal/cancel-modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { isEmpty, isEqual } from 'lodash';
import { getTechCardName } from '@/shared/core/utils/tech-card-utils';
import { MadIcon } from 'madsoft-icons';
import { PopoverTechCard } from './popover/popover-techcard';
import { ActionsTechCardAssemblyMenu } from './actions-tech-card-menu/action-tech-card-assembly-menu';
import { CanceledStatusModal } from '@/entities/tables/tech-card';
import printJS from 'print-js';
import { CommentBadge } from '@/entities/tech-map';

export const TechCardPageAssembly: FC = () => {
	const [create, createInfo] = useCreateAssemblyTechMapMutation();
	const [getAdditionalFile, { isFetching: isAdditionalFileFetching }] = useLazyGetFileQuery();
	const {
		// объект с данными о тех-карте
		assemblyCard,
		// объект с состоянием получения объекта карты (isSuccess, isFailed, isLoading)
		assemblyCardInfo,
		// режим создания новой карты
		isCreationMode,
		// режим редактирования существующей карты
		isEditMode,
		setIsEditMode,
		isInitialEditMode,
		userRole,
		handleAssemblySubmit,
		// изменение статуса карты
		cancelAssemblyTechMap,
	} = useTechCard();

	const location = useLocation();
	const toast = useToast();
	const navigate = useNavigate();

	const [isResetMode, setIsResetMode] = useState(false);

	const printeableAreaRef = useRef<HTMLDivElement>(null);
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [isPrinting, setIsPrinting] = useState(false);

	// Без мемоизации меняется ссылка на функцию, что триггерит изменение handlePrint
	const handleAfterPrint = useCallback(() => {
		setIsPrinting(false);
		if (assemblyCard?.number) {
			navigate(LINK_TO_TECH_CARD_ASSEMBLY_INFO(assemblyCard.number), {
				state: { isPrinting: false },
			});
		}
	}, [assemblyCard]);
	// Без мемоизации меняется ссылка на функцию, что триггерит изменение handlePrint
	const handleAdditionalFileDownloadError = useCallback(() => {
		showErrorToast(toast, { description: 'При скачивании файла произошла ошибка' });
	}, []);

	const handlePrint = useReactToPrint({
		content: () => printeableAreaRef.current,
		removeAfterPrint: true,
		copyStyles: true,
		onAfterPrint: async () => {
			if (assemblyCard?.file?.id) {
				try {
					const file = (await getAdditionalFile({
						id: assemblyCard?.file?.id,
					}).unwrap()) as unknown as Blob;

					printJS({
						printable: URL.createObjectURL(file),
						onPrintDialogClose: handleAfterPrint,
						onError: handleAdditionalFileDownloadError,
					});
				} catch (error) {
					handleAdditionalFileDownloadError();
				}
			}
			handleAfterPrint();
		},
	});

	useEffect(() => {
		// Для правильной печати необхоимо дождаться пока у нас пояфится информация о карте
		if (
			location.state?.isPrinting &&
			printeableAreaRef.current &&
			assemblyCard &&
			!assemblyCardInfo.isFetching &&
			!assemblyCardInfo.isUninitialized
		) {
			// если все готово, то меняем стейт на печать
			setIsPrinting((prev) => {
				// Если предыдущий стейт был false, то запускаем печать, чтобы избежать зацикливания и несколько повторных вызовов
				if (!prev) {
					// Так как нам нужно дождаться набивку пустыми полям, делаем это через таймаут
					setTimeout(() => {
						handlePrint();
					}, 10);
				}

				return true;
			});
		}
	}, [location.state, handlePrint, assemblyCardInfo.isFetching, assemblyCardInfo.isUninitialized, assemblyCard]);

	const formProps = useAssemblyFormConfig(isCreationMode, userRole, assemblyCard);

	const handleCreateTechCard = () => {
		formProps.handleSubmit(() => {
			const payload = formProps.getValues();
			if (payload) {
				const preparedRequest = {
					wasted: payload.wasted,
					plan: payload.plan,
					symbol: payload.symbol,
					assembly: {
						symbol: payload?.assembly?.symbol ?? '',
						name: payload?.assembly?.name ?? '',
					},
					assembly_products: payload.assembly_products.map((item) => ({
						product_symbol: item.product_symbol,
						product_name: item.product_name,
						size: item.size,
						product_tech_map_id: item.product_tech_map_id,
						product_sgd_out: {
							...item.product_sgd_out,
							count: Number.isNaN(Number(item?.product_sgd_out?.count))
								? undefined
								: Number(item?.product_sgd_out?.count),
						},
					})),
					assembly_operations:
						payload.assembly_operations?.map((item) => ({
							step_id: item.step_id,
							name: item.name,
						})) || [],
					approval_card: payload.approval_card,
					tech_decision: payload.tech_decision,
					fio_pds: payload.fio_pds,
					created_at: payload.created_at
						? new Date(payload.created_at).toISOString()
						: new Date().toISOString(),
					file_id: payload.file_id,
				} satisfies AssemblyTechMapCreateRequest;

				create({ assemblyTechMapCreateRequest: preparedRequest })
					.unwrap()
					.then((data) => {
						showSuccessToast(toast, {
							title: 'Маршрутная карта создана',
							description: `Вы создали карту № ${data.number}`,
							duration: 6000,
						});
						setIsEditMode(false);

						if (data?.number) {
							navigate(LINK_TO_TECH_CARD_ASSEMBLY_INFO(data?.number), {
								state: { isPrinting: true },
							});
						}
					})
					.catch(() => {
						showErrorToast(toast, {
							title: 'Не удалось сохранить',
							description: 'При создании карты произошла ошибка',
						});
					});
			}
		})();
		setIsEditMode(false);
		setIsPrinting(true);
	};

	const handleCancelClick = () => {
		formProps.reset();
		setIsEditMode(false);
		if (isInitialEditMode || isCreationMode || isEditMode) {
			navigate(TECH_CARDS_PATH);
		}
	};

	const isLoaded = !createInfo.isLoading && !assemblyCardInfo.isLoading;

	const { count: commentsCount, isFetching: isCommentsFetching } = useGetAssemblyTechMapCommentsQuery(
		{ assemblyTechMapId: assemblyCard?.id ?? 0 },
		{
			skip: !assemblyCard?.id,
			selectFromResult: (result) => ({ ...result, count: result?.data?.length ?? 0 }),
		},
	);
	const [commentsModalOpen, setCommentsModalOpen] = useState(false);

	return (
		<>
			<Layout
				withSidebar={false}
				backArrow={
					<Button
						as={IconButton}
						icon={<ArrowBackIcon />}
						size="md"
						variant="ghost"
						colorScheme="tertiary"
						backgroundColor="transparent"
						onClick={() => {
							if (!isEqual(formProps.formState.defaultValues, formProps.getValues()) && isEditMode) {
								setIsCancelModalOpen(true);
							} else {
								handleCancelClick();
								navigate(TECH_CARDS_PATH);
							}
						}}
					/>
				}
				title={
					<>
						<TextHeader className={styles['tech-card__title']} variant="h1">
							{isCreationMode
								? 'Создание карты'
								: isEditMode
								? `Редактирование карты № ${getTechCardName(assemblyCard)}`
								: `Маршрутная карта № ${getTechCardName(assemblyCard)}`}
						</TextHeader>
						{assemblyCard?.status && (
							<span
								style={{
									backgroundColor: `${TechCardStatusColors[assemblyCard?.status || '']}`,
									width: '8px',
									height: '8px',
									minWidth: '8px',
									minHeight: '8px',
									borderRadius: '50%',
								}}
							/>
						)}
					</>
				}
				headerLeftButton={
					isEditMode && !isCreationMode ? (
						<ButtonGroup>
							<Button
								size="md"
								variant="ghost"
								colorScheme="tertiary"
								onClick={() => {
									if (!isEqual(formProps.formState.defaultValues, formProps.getValues())) {
										setIsCancelModalOpen(true);
									} else {
										handleCancelClick();
									}
								}}
								tabIndex={-1}
							>
								Отменить
							</Button>
							<Tooltip isDisabled={isEmpty(formProps.formState.errors)} label={'Заполните нужные поля'}>
								<Button
									size="md"
									variant="ghost"
									colorScheme="primary"
									rightIcon={
										<MadIcon
											module="basic"
											type="outline"
											size="default"
											mode="default"
											name="chevron-right"
										/>
									}
									onClick={() => {
										if (isEqual(formProps.formState.defaultValues, formProps.getValues())) {
											setIsEditMode(false);
										} else {
											formProps.handleSubmit(() => {
												handleAssemblySubmit(
													formProps.formState
														?.defaultValues as Partial<AssemblyTechMapResponse>,
													formProps.getValues(),
													{ exitAfterSubmit: false },
												);
											})();
										}
									}}
									tabIndex={-1}
									isDisabled={!isEmpty(formProps.formState.errors) || !isLoaded}
								>
									Сохранить изменения
								</Button>
							</Tooltip>
						</ButtonGroup>
					) : !isEditMode && !isCreationMode ? (
						assemblyCard?.status === TechCardStatuses.canceled ? null : (
							<Button
								size="md"
								variant="ghost"
								colorScheme="tertiary"
								leftIcon={
									<MadIcon
										module="basic"
										size="default"
										mode="default"
										type="outline"
										name="pencil-square"
									/>
								}
								onClick={() => setIsEditMode(true)}
							>
								Отредактировать
							</Button>
						)
					) : (
						<Tooltip label={'Заполните обязательные поля'} isDisabled={!!formProps.formState.isValid}>
							<Button
								size="md"
								variant="ghost"
								colorScheme="primary"
								lineHeight="24px"
								rightIcon={<ChevronRightIcon />}
								isDisabled={!formProps.formState.isValid || createInfo.isLoading || !!createInfo.data}
								onClick={() => handleCreateTechCard()}
								tabIndex={-1}
							>
								Создать и напечатать
							</Button>
						</Tooltip>
					)
				}
				headerRightButton={
					!isCreationMode &&
					!isEditMode && (
						<ButtonGroup>
							<Button
								size="md"
								variant="ghost"
								colorScheme="tertiary"
								leftIcon={
									<MadIcon
										module="basic"
										type="outline"
										size="default"
										mode="default"
										name="printer"
									/>
								}
								onClick={() => {
									setIsPrinting(true);
									setTimeout(handlePrint, 10);
								}}
							>
								Распечатать
							</Button>
							{!!assemblyCard?.id && (
								<Button
									size="md"
									variant="ghost"
									colorScheme="tertiary"
									onClick={() => setCommentsModalOpen(true)}
									isLoading={isCommentsFetching}
									leftIcon={<CommentBadge isLoading={isCommentsFetching} count={commentsCount} />}
								>
									Комментарии
								</Button>
							)}
							<ActionsTechCardAssemblyMenu setIsResetMode={setIsResetMode} />
						</ButtonGroup>
					)
				}
			>
				<form autoComplete="off">
					<CancelModal
						isCreationMode={isCreationMode}
						isCancelModalOpen={isCancelModalOpen}
						onClose={() => setIsCancelModalOpen(false)}
						handleMainAction={() => {
							if (isEqual(formProps.formState.defaultValues, formProps.getValues())) {
								setIsEditMode(false);
								navigate(TECH_CARDS_PATH);
							} else {
								formProps.handleSubmit(() => {
									handleAssemblySubmit(
										formProps.formState?.defaultValues as Partial<AssemblyTechMapResponse>,
										formProps.getValues(),
										{ exitAfterSubmit: true },
									);
								})();
							}
						}}
						handleCancelClick={handleCancelClick}
					/>
					<FormProvider {...formProps}>
						{isLoaded ? (
							<>
								<TechCardAssembly
									isEditable={isEditMode}
									ref={printeableAreaRef}
									isPrinting={isPrinting}
									isCreationMode={isCreationMode}
									focusedBlock={location.state?.focusedBlock ? location.state.focusedBlock : null}
								/>
								<PopoverTechCard
									isCreationMode={isCreationMode}
									isInitialEditMode={isInitialEditMode}
									isEditMode={isEditMode}
								/>
							</>
						) : (
							<Loader />
						)}
						{isAdditionalFileFetching ? (
							<MainContentLoader color="white" position={'fixed'} withOverlay>
								<Box fontSize={'md'} color="white" fontWeight={500}>
									Загружаем приложение к МК...
								</Box>
							</MainContentLoader>
						) : (
							<></>
						)}
					</FormProvider>
				</form>
			</Layout>
			{isResetMode && (
				<CanceledStatusModal
					isOpen={isResetMode}
					onClose={() => setIsResetMode(false)}
					onCancelTechMap={cancelAssemblyTechMap}
				/>
			)}
			{commentsModalOpen && (
				<TechCardAssemblyCommentModal
					isOpen={commentsModalOpen}
					onClose={() => setCommentsModalOpen(false)}
					techMapId={Number(assemblyCard?.id)}
				/>
			)}
		</>
	);
};
