import { TechCardStatuses } from '@/shared';
import { AssemblyTechMapResponse, UserRoleEnum } from '@/shared/state/api/swagger';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

const validateCount = (val: any) => !val || !isNaN(Number(val)) || /^-{1,}$/.test(String(val));

export const creationAssemblyScheme = yup.object({
	created_at: yup.string().nullable(),
	wasted: yup.number().nullable(),
	plan: yup.number().nullable(),
	symbol: yup.string().nullable(),
	assembly: yup.object({
		symbol: yup.string().required().default(''),
		name: yup.string().required().default(''),
	}),
	assembly_operations: yup.array().of(
		yup.object({
			step_id: yup.number().required(),
			name: yup.string().required().default(''),
			fio: yup.string().nullable(),
			date: yup.string().nullable(),
			count_in_number: yup.number().nullable(),
			count_out_number: yup
				.mixed()
				.nullable()
				.test('', 'count in number less then count out number', (val, ctx) => {
					if (!val) return true;
					if (
						(ctx.parent.count_in_number === '-' || ctx.parent.count_in_number === null) &&
						(val === '-' || !isNaN(Number(val)))
					)
						return true;
					if (val === '-') return true;
					if (!isNaN(Number(val)) && val !== 0 && Number(val) <= Number(ctx.parent.count_in_number))
						return true;
				}),
			wasted: yup.number().nullable(),
		}),
	),
	assembly_products: yup.array().of(
		yup.object({
			product_symbol: yup.string().required().default(''),
			product_name: yup.string().required().default(''),
			size: yup.string().nullable(),
			product_tech_map_id: yup.number().nullable(),
			product_sgd_out_id: yup.number().nullable(),
		}),
	),
	approval_card: yup.string().nullable(),
	tech_decision: yup.string().nullable(),
	fio_pds: yup.string().nullable(),
});

export const editAssemblyScheme = yup.object({
	number: yup
		.string()
		.min(3)
		.test('is-valid-number', 'number not valid', (val) => {
			const year = Number((val ?? '')?.trim().slice(0, 2));

			if (Number.isNaN(year)) return false;

			return new Date().getFullYear() % 100 >= year;
		}),
	wasted: yup.number().nullable(),
	plan: yup.number().nullable(),
	symbol: yup.string().nullable(),
	assembly: yup.object({
		symbol: yup.string().required().default(''),
		name: yup.string().required().default(''),
	}),
	assembly_operations: yup.array().of(
		yup.object({
			id: yup.number().integer().nullable(),
			step_id: yup.number().nullable(),
			name: yup.string().nullable().default(''),
			fio: yup.string().nullable(),
			date: yup.string().nullable(),
			count_in_number: yup.mixed().nullable().test('validity', '', validateCount),
			count_out_number: yup
				.mixed()
				.nullable()
				.test('', 'count in number less then count out number', (val, ctx) => {
					if (!val) return true;
					if (
						(ctx.parent.count_in_number === '-' || ctx.parent.count_in_number === null) &&
						(val === '-' || !isNaN(Number(val)))
					)
						return true;
					if (val === '-') return true;
					if (!isNaN(Number(val)) && val !== 0 && Number(val) <= Number(ctx.parent.count_in_number))
						return true;
				}),
			wasted: yup.number().nullable(),
		}),
	),
	assembly_products: yup.array().of(
		yup.object({
			product_symbol: yup.string().required().default(''),
			product_name: yup.string().required().default(''),
			size: yup.string().nullable(),
			product_tech_map_id: yup.number().nullable(),
			product_sgd_out_id: yup.number().nullable(),
			product_sgd_out: yup
				.object({
					id: yup.number().nullable(),
					count: yup.number().nullable(),
					date: yup.string().nullable(),
				})
				.nullable(),
		}),
	),
	approval_card: yup.string().nullable(),
	tech_decision: yup.string().nullable(),
	fio_pds: yup.string().nullable(),
});

export type AssemblyConfig = Omit<AssemblyTechMapResponse, 'user_id' | 'id'>;

export const useAssemblyFormConfig = (
	isCreationMode: boolean,
	userRole?: UserRoleEnum,
	cardInfo?: AssemblyTechMapResponse,
) => {
	const emptyObjectArray = (length: number) => {
		return new Array(length).fill({});
	};

	const sgdOutField = (length: number) => {
		switch (length) {
			case 0:
				return emptyObjectArray(3);
			default:
				return [...(cardInfo?.assembly_sgd_out || []), cardInfo?.assembly_sgd_out?.length ?? 0];
		}
	};

	const initialValues = useMemo<AssemblyConfig>(
		() => ({
			number: cardInfo?.number,
			wasted: cardInfo?.wasted,
			plan: cardInfo?.plan,
			symbol: cardInfo?.symbol,
			status: cardInfo?.status,
			created_at: cardInfo?.created_at,
			assembly_products: cardInfo?.assembly_products ?? [],
			assembly_operations: cardInfo?.assembly_operations,
			assembly: cardInfo?.assembly,
			assembly_sgd_out:
				cardInfo?.status === TechCardStatuses.accepted_at_sgd ||
				cardInfo?.status === TechCardStatuses.partially_issued
					? sgdOutField(cardInfo?.assembly_sgd_out?.length ?? 0)
					: undefined,
			approval_card: cardInfo?.approval_card,
			tech_decision: cardInfo?.tech_decision,
			fio_pds: cardInfo?.fio_pds,
		}),
		[cardInfo],
	);

	const formConfig = useForm<AssemblyConfig>({
		resolver: yupResolver(isCreationMode ? creationAssemblyScheme : editAssemblyScheme),
		values: initialValues,
		mode: 'all',
	});

	return formConfig;
};
