import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import type { FC } from 'react';
import type {
	ITechCardTable,
	TechCardsFiltersArg,
	TechCardsFiltersNullableArg,
	TechMapData,
	TechMapType,
} from './tech-card-table.types';

import { Badge, useToast } from '@chakra-ui-kraud/react';
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { TechCardActions } from '@/widgets/tech-card-actions';
import { CommonTable } from '@/features/common-table';
import { DEFAULT_YEAR_FORMAT, TechCardStatusColors } from '@/shared';
import { Loader } from '@/shared/components/loader';
import { CellTableParagraph } from '@/shared/components/cell-table-paragraph/cell-table-paragraph';
import { showErrorToast } from '@/shared/components/toasts';
import { formatCurrentOperation } from '@/shared/core/utils/tech-card-utils';
import {
	LINK_TO_TECH_CARD_ASSEMBLY_INFO,
	LINK_TO_TECH_CARD_INFO,
	LINK_TO_TECH_CARD_PREFORM_INFO,
	TechCardStatuses,
} from '@/shared/core';
import {
	PreformTechMapResponse,
	ProductTechMapResponse,
	TechMapForAll,
	useGetTechMapExcelMutation,
} from '@/shared/state/api/swagger';

import styles from './tech-card-table.module.scss';
import { MAP_STATUS_TO_NAME } from '@/entities/tech-map';
import { TECH_MAP_ALL_COLUMNS } from './model/columns';
import { TechCardActionsProps } from '@/widgets/tech-card-actions/tech-card-actions.types';

export const TechCardsTable: FC<ITechCardTable> = ({
	data,
	isLoading,
	superHeader,
	setQueryParams,
	queryParams,
	type,
}) => {
	const toast = useToast();

	const [getExcel] = useGetTechMapExcelMutation();

	const [activeMenuItem, setActiveMenuItem] = useState<null | number>(null);

	const getFile = useCallback((value: ProductTechMapResponse) => {
		value.number &&
			getExcel({ productTechMapNumber: value.number })
				.unwrap()
				.then((res) => {
					const href = window.URL.createObjectURL(new Blob([res]));
					const link = document.createElement('a');
					link.href = href;
					link.download = `${value}.xlsx`;
					link.click();
					link.remove();
				})
				.catch(() => {
					showErrorToast(toast, { description: 'При скачивании Excel произошла ошибка' });
				});
	}, []);

	const TECH_CARD_COLUMNS = useMemo(() => {
		const onHeaderCell = () => ({
			style: {
				width: '100%',
				whiteSpace: 'normal',
			},
		});

		if (type === 'all') {
			return [
				...TECH_MAP_ALL_COLUMNS(onHeaderCell),
				{
					key: 'actions',
					dataIndex: 'number',
					title: 'Действия',
					align: 'center',
					onHeaderCell,
					width: '110px',
					render: (_, row) => {
						return row.number ? (
							<TechCardActions
								typeTabs={type}
								type={row.type.replace('_tech_map', '') as TechMapType}
								onExcel={getFile}
								status={row.status as TechCardActionsProps['status']}
								value={row}
								activeMenuItem={activeMenuItem}
								setActiveMenuItem={setActiveMenuItem}
								itemId={row.number | 0}
							/>
						) : (
							<></>
						);
					},
					fixed: 'right',
				},
			] as ColumnsType<TechMapForAll>;
		}

		if (type === 'preform') {
			return [
				{
					key: 'number',
					title: 'Номер карты',
					dataIndex: 'number',
					align: 'left',
					className: styles['preform_number'],
					sorter: true,
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => (
						<Link color="red" className={styles.link} to={LINK_TO_TECH_CARD_PREFORM_INFO(value)}>
							<CellTableParagraph value={value} />
						</Link>
					),
					fixed: 'left',
				},
				{
					key: 'preform_catalog.name',
					title: 'Шаблона тех. процесса',
					dataIndex: 'preform_name',
					align: 'left',
					className: styles['preform_name'],
					sorter: true,
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => <CellTableParagraph value={value} />,
				},
				{
					key: `num`,
					dataIndex: `num`,
					title: 'НУМ',
					sorter: true,
					className: styles['preform_num'],
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => <CellTableParagraph value={value} />,
				},
				{
					key: `num_symbol`,
					dataIndex: `num_symbol`,
					title: 'Марка материала',
					className: styles['preform_material'],
					sorter: true,
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => <CellTableParagraph value={value} />,
				},
				{
					key: `num_gost`,
					dataIndex: `num_gost`,
					title: 'ТУ, ГОСТ',
					className: styles['preform_gost'],
					sorter: true,
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => <CellTableParagraph value={value} />,
				},
				{
					key: 'num_sortament',
					dataIndex: 'num_sortament',
					title: 'Сортамент',
					sorter: true,
					className: styles['preform_sortament'],
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => <CellTableParagraph value={value} />,
				},

				{
					key: 'num_gost_sortament',
					dataIndex: 'num_gost_sortament',
					title: 'ТУ, ГОСТ на сортамент',
					align: 'left',
					sorter: true,
					className: styles['preform_gost_sortament'],
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => <CellTableParagraph value={value} />,
				},
				{
					key: 'status',
					dataIndex: 'status',
					title: 'Статус',
					sorter: true,
					className: styles['preform_status'],
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value: TechCardStatuses) => (
						<Badge
							variant={'solid'}
							background={TechCardStatusColors[value]}
							borderRadius="6px"
							textTransform="initial"
							fontFamily="Raleway"
							lineHeight="16px"
							fontWeight={600}
							padding="4px 8px 4px 8px"
							style={{ width: 'fit-content' }}
						>
							<CellTableParagraph value={MAP_STATUS_TO_NAME[value]} />
						</Badge>
					),
				},
				{
					key: 'created_at',
					dataIndex: 'created_at',
					title: 'Дата создания',
					align: 'left',
					sorter: true,
					className: styles['preform_create_at'],
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => (
						<CellTableParagraph
							value={dayjs(value).isValid() ? dayjs(value).format(DEFAULT_YEAR_FORMAT) : undefined}
							textAlign={'right'}
						/>
					),
				},
				{
					key: 'current_details_count',
					dataIndex: 'current_details_count',
					title: 'Годных',
					align: 'left',
					sorter: true,
					className: styles['preform_current-details-count'],
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => <CellTableParagraph value={value} textAlign={'right'} />,
				},
				{
					key: 'wasted',
					dataIndex: 'wasted',
					title: 'Брак',
					align: 'left',
					sorter: true,
					className: styles['preform_wasted'],
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => <CellTableParagraph value={value} textAlign={'right'} />,
				},
				{
					key: 'return_count',
					dataIndex: 'return_count',
					title: 'Возврат',
					align: 'left',
					sorter: true,
					className: styles['preform_remaining_count'],
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => <CellTableParagraph value={value} textAlign={'right'} />,
				},
				{
					key: 'remaining_count',
					dataIndex: 'remaining_count',
					title: 'На складе',
					align: 'left',
					sorter: true,
					className: styles['preform_remaining_count'],
					sortDirections: ['ascend', 'descend'],
					onHeaderCell,
					render: (value) => <CellTableParagraph value={value} textAlign={'right'} />,
				},
				{
					key: 'actions',
					dataIndex: 'number',
					title: 'Действия',
					align: 'center',
					onHeaderCell,
					width: '110px',
					render: (_, row) => {
						return row.number ? (
							<TechCardActions
								type={type}
								onExcel={getFile}
								status={row.status}
								value={row}
								activeMenuItem={activeMenuItem}
								setActiveMenuItem={setActiveMenuItem}
								itemId={row.number | 0}
							/>
						) : (
							<></>
						);
					},
					fixed: 'right',
				},
			] as ColumnsType<PreformTechMapResponse>;
		}

		return [
			{
				key: 'group',
				dataIndex: 'group',
				title: 'Группа',
				align: 'left',
				className: styles['group'],
				sorter: false,
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (_, row) => {
					const _row = row as ProductTechMapResponse;
					return <CellTableParagraph value={_row?.symbol} /> /** @todo не уверен, что это поле */;
				},
				fixed: 'left',
			},
			{
				key: 'number',
				title: 'Номер карты',
				dataIndex: 'number',
				align: 'left',
				className: styles['number'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => (
					<Link
						color="red"
						className={styles.link}
						to={type === 'product' ? LINK_TO_TECH_CARD_INFO(value) : LINK_TO_TECH_CARD_ASSEMBLY_INFO(value)}
					>
						<CellTableParagraph value={value} />
					</Link>
				),
				fixed: 'left',
			},
			{
				key: `${type}_catalog.symbol`,
				dataIndex: `${type}_catalog.symbol`,
				title: 'Обозначение',
				sorter: true,
				className: styles['labeling'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (_, row) => {
					const _row = row as ProductTechMapResponse;
					return <CellTableParagraph value={_row?.product?.symbol} />;
				},
			},
			{
				key: `${type}_catalog.name`,
				dataIndex: `${type}_catalog.name`,
				title: 'Наименование',
				className: styles['naming'],
				sorter: true,
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (_, row) => {
					const _row = row as ProductTechMapResponse;
					return <CellTableParagraph value={_row?.product?.name} />;
				},
			},
			{
				key: 'num.num',
				dataIndex: 'num.num',
				title: 'НУМ',
				className: styles['preform_num'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (_, row) => <CellTableParagraph value={row?.num?.num} />,
			},
			{
				key: `current_${type}_operation`,
				dataIndex: `current_${type}_operation`,
				title: 'Текущая операция',
				className: styles['current_operation'],
				sorter: false,
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (_, row) => {
					const _row = row as ProductTechMapResponse;
					return (
						<CellTableParagraph
							value={formatCurrentOperation(
								_row?.current_product_operation?.step_id,
								_row?.current_product_operation?.name,
							)}
						/>
					);
				},
			},
			{
				key: 'status',
				dataIndex: 'status',
				title: 'Статус',
				sorter: true,
				className: styles['status'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value: TechCardStatuses) => (
					<Badge
						variant={'solid'}
						background={TechCardStatusColors[value]}
						borderRadius="6px"
						textTransform="initial"
						fontFamily="Raleway"
						lineHeight="16px"
						fontWeight={600}
						padding="4px 8px 4px 8px"
						style={{ width: 'fit-content' }}
					>
						<CellTableParagraph value={MAP_STATUS_TO_NAME[value]} />
					</Badge>
				),
			},

			{
				key: 'created_at',
				dataIndex: 'created_at',
				title: 'Дата создания',
				align: 'left',
				sorter: true,
				className: styles['created-at'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => (
					<CellTableParagraph value={dayjs(value).format(DEFAULT_YEAR_FORMAT)} textAlign={'right'} />
				),
			},
			{
				key: 'storage_at',
				dataIndex: 'storage_at',
				title: 'Дата принятия на СГД',
				align: 'left',
				sorter: true,
				className: styles['storage-at'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => (
					<CellTableParagraph
						value={dayjs(value).isValid() ? dayjs(value).format(DEFAULT_YEAR_FORMAT) : undefined}
						textAlign={'right'}
					/>
				),
			},
			{
				key: 'current_details_count',
				dataIndex: 'current_details_count',
				title: 'Годных',
				align: 'left',
				sorter: false,
				className: styles['current-details-count'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => <CellTableParagraph value={value} textAlign={'right'} />,
			},
			{
				key: 'wasted',
				dataIndex: 'wasted',
				title: 'Брак',
				align: 'left',
				sorter: true,
				className: styles['wasted'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => <CellTableParagraph value={value && value > 0 ? value : ''} textAlign={'right'} />,
			},
			{
				key: 'remaining_count',
				dataIndex: 'remaining_count',
				title: 'На складе',
				align: 'left',
				sorter: true,
				className: styles['remaining_count'],
				sortDirections: ['ascend', 'descend'],
				onHeaderCell,
				render: (value) => (
					<CellTableParagraph noFormatting value={value === null ? '\u2014' : value} textAlign={'right'} />
				),
			},
			{
				key: 'actions',
				dataIndex: 'number',
				title: 'Действия',
				align: 'center',
				onHeaderCell,
				width: '110px',
				render: (_, row) => {
					return row.id ? (
						<TechCardActions
							type={type}
							onExcel={getFile}
							status={row.status}
							value={row}
							activeMenuItem={activeMenuItem}
							setActiveMenuItem={setActiveMenuItem}
							itemId={row.id}
						/>
					) : (
						<></>
					);
				},
				fixed: 'right',
			},
		] as ColumnsType<ProductTechMapResponse>;
	}, [activeMenuItem, getFile, type]);

	const handleTableChange = useCallback(
		(
			pagination: TablePaginationConfig,
			filters: Record<string, FilterValue | null>,
			sorter: SorterResult<TechMapData> | SorterResult<TechMapData>[],
		) => {
			const sortedBy = (
				Array.isArray(sorter) ? sorter[0].column?.key?.toString() : sorter.column?.key?.toString()
			) as TechCardsFiltersNullableArg['orderBy'];

			const sortOrder = Array.isArray(sorter) ? sorter[0].order : sorter.order;
			const preparedSortDir = sortOrder ? (sortOrder === 'ascend' ? 'asc' : 'desc') : undefined;

			console.debug(sortedBy, sortOrder, preparedSortDir);

			setQueryParams(
				(prev) =>
					({
						...prev,
						orderBy: sortedBy,
						orderDir: preparedSortDir,
					} as TechCardsFiltersArg),
			);
		},
		[],
	);

	return !isLoading ? (
		<CommonTable
			superHeader={superHeader}
			rowKey={type === 'all' ? 'number' : 'id'}
			columns={TECH_CARD_COLUMNS}
			data={data?.payload || []}
			onChange={handleTableChange}
			pageSize={queryParams.pageSize ?? 32}
			total={data?.total}
			page={data?.page}
			setQueryParams={setQueryParams}
		/>
	) : (
		<Loader />
	);
};
