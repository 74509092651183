import { Executor, UserInitialsResponse } from '@/shared/state/api/swagger';

export const selectExecutorsForOptions = (employees: Executor[] = []) =>
	employees?.map((employee) => ({
		value: `${employee.id}_${employee.name}`,
		label: employee.name,
	})) || [];
export const selectEmployeesInitials = (employees: UserInitialsResponse[] = []) =>
	employees?.map((employee) => ({
		value: employee?.id,
		label: employee?.initials,
	})) || [];
