import { TechCardStatuses } from '../core';
import { StandardConsumptionTypeEnum } from '../state/api/swagger';

export const pirntAreaBlockId = 'print-area';
export const mainBlockId = 'main-block';

export const API_URL = '/api';
export const TOAST_DURATION = 3000;
export const REDIRECT_PARAM = 'redirect';
export const COMPLECT_OPERATION_STEP_ID = 5;

export const TechCardStatusColors: Record<string, string> = {
	[TechCardStatuses.released]: '#60D87C',
	[TechCardStatuses.accepted_at_sgd]: '#F3AE45',
	[TechCardStatuses.canceled]: '#4A5568',
	[TechCardStatuses.partially_issued]: '#F88662',
	[TechCardStatuses.progress]: '#0FB3FA',
	[TechCardStatuses.completed]: '#60D87C',
	[TechCardStatuses.accepted_at_storage]: '#F3AE45',
	[TechCardStatuses.wasted]: '#FC4C06',
};

export enum LocalStorageKeys {
	IS_USER_FIRST_ENTER = 'is_user_first_enter',
}

export const FIRE_USER_CACHE_KEY = 'fire-user';
export const ACTIVATE_USER_CACHE_KEY = 'activate-user';

export const Tooltips: Record<string, string> = {
	PRODUCT_CATALAG_TEXT_NUM: 'Поле обязательно для заполнения',
	PRODUCT_CATALOG_TEXT: 'Поле обязательно для заполнения. Вы так же можете поставить «—»',
	CONSUMPTION_SECTION_TEXT: 'Обязательно заполните хотя бы одно поле',
	OPERATIONS_SECTION_TEXT: 'Заполните это поле, оно обязательное',
	SELECT_TEXT: 'Выберете значение',
	DATE_REQUIRED: 'Введите дату',
	NUMBER_REQUIRED: 'Введите число',
};

export const STANDARD_CONSUMPTION_TYPES: Record<StandardConsumptionTypeEnum, string> = {
	grams: 'г',
	kg: 'кг',
	meters: 'м',
	square_meters: 'м²',
	old: 'м',
};
export const RETURN_STANDARD_CONSUMPTION_TYPES: Record<StandardConsumptionTypeEnum, string> = {
	grams: 'г',
	kg: 'кг',
	meters: 'м х шт.',
	square_meters: '(мхм) х шт.',
	old: 'м',
};

export const DEFAULT_YEAR_FORMAT = 'DD.MM.YY';
export const DEFAULT_DATE_FNS_YEAR_FORMAT = 'dd.MM.yy';

export const DEFAULT_PAGE_SIZE = 32;
