import type { FC } from 'react';
import type { AssemblyModalProps } from '../model/interfaces/assembly-modal.types';

import { useCallback, useEffect, useMemo } from 'react';
import { Button, ButtonGroup, useToast } from '@chakra-ui-kraud/react';
import { FormProvider, useForm } from 'react-hook-form';

import { AssemblyForm } from '@/entities/forms/assembly-form';
import { IAssemblyFormData } from '@/entities/forms/assembly-form/model/interfaces/assembly-form.types';
import { showErrorToast, showSuccessToast, SideModal } from '@/shared';
import {
	useAddFileToAssemblyMutation,
	useCreateAssemblyMutation,
	useUpdateAssemblyMutation,
} from '@/shared/state/api/swagger';
import { yupResolver } from '@hookform/resolvers/yup';
import { assemblyCreateValidation } from '@/entities/forms/assembly-form/model/validations/assembly-validation';

export const AssemblyModal: FC<AssemblyModalProps> = ({ isOpen, onClose, variant, selectedAssembly }) => {
	const toast = useToast();
	const [addFileToAssembly, { isLoading: isFileUpdateLoading }] = useAddFileToAssemblyMutation();

	const defaultValues = useMemo<IAssemblyFormData>(() => {
		return {
			name: selectedAssembly?.name ?? '',
			symbol: selectedAssembly?.symbol ?? '',
			file: selectedAssembly?.file,
			products: selectedAssembly?.products?.length
				? selectedAssembly.products.map((product) => ({
						product_name: product.product_name,
						product_symbol: product.product_symbol,
						value: `${product.product_symbol} (${product.product_name})`,
				  }))
				: [{ product_name: '', product_symbol: '', value: '' }],
			operation_types: selectedAssembly?.operation_types?.length
				? selectedAssembly.operation_types.map((operation, idx) => ({
						step_id: operation.step_id ?? (idx + 2) * 5,
						name: operation.name ?? '',
						id: operation.operation_type_id ?? idx * -1,
				  }))
				: [
						{
							step_id: 10,
							name: '',
							id: -1,
						},
						{
							step_id: 15,
							name: 'Консервация',
							id: -2,
						},
				  ],
		};
	}, [variant, selectedAssembly]);

	const form = useForm<IAssemblyFormData>({
		defaultValues,
		resolver: yupResolver(assemblyCreateValidation),
	});

	const [createAssembly, { isLoading: isCreateLoading }] = useCreateAssemblyMutation();
	const [updateAssembly, { isLoading: isUpdateLoading }] = useUpdateAssemblyMutation();
	const isLoading = isCreateLoading || isUpdateLoading || isFileUpdateLoading;

	const onSubmit = useCallback(
		(data: IAssemblyFormData) => {
			const formattedData = {
				...data,
				products: data.products?.map((product) => ({
					product_name: product.product_name,
					product_symbol: product.product_symbol,
				})),
				operation_types: data.operation_types.map((operation) => ({
					name: operation.name,
					step_id: operation.step_id,
				})),
			};

			if (variant === 'create') {
				createAssembly({
					assemblyCatalogCreateRequest: formattedData,
				})
					.unwrap()
					.then((assembly) => {
						if (data?.file && data?.file instanceof Blob && assembly?.id) {
							return addFileToAssembly({
								bodyAddFileToAssembly: { file: data.file },
								assemblyId: assembly?.id,
							});
						}
					})
					.then(() => {
						showSuccessToast(toast, { description: 'Сборочная единица создана' });
						onClose();
						form.reset();
					})
					.catch((err) => {
						console.error(err);
						showErrorToast(toast, { title: 'При создании сборочной единицы произошла ошибка' });
					});
			} else if (variant === 'edit' && selectedAssembly) {
				updateAssembly({ assemblyId: selectedAssembly.id, assemblyCatalogUpdateRequest: formattedData })
					.unwrap()
					.then((assembly) => {
						if (data?.file && data?.file instanceof Blob && assembly?.id) {
							return addFileToAssembly({
								bodyAddFileToAssembly: { file: data.file },
								assemblyId: assembly?.id,
							});
						}
					})
					.then(() => {
						showSuccessToast(toast, { description: 'Сборочная единица обновлена' });
						onClose();
						form.reset();
					})
					.catch((err) => {
						console.error(err);
						showErrorToast(toast, { title: 'При обновлении сборочной единицы произошла ошибка' });
					});
			}
		},
		[variant, selectedAssembly, createAssembly, addFileToAssembly, toast, onClose, form, updateAssembly],
	);

	useEffect(() => {
		form.reset(defaultValues);
	}, [variant, selectedAssembly, isOpen]);

	return (
		<SideModal
			title={variant === 'edit' ? 'Редактирование сборочной единицы' : 'Добавление сборочной единицы'}
			isOpen={isOpen}
			onClose={onClose}
			maxWidth={'644px'}
			footer={
				<ButtonGroup>
					<Button size="md" variant="ghost" colorScheme="tertiary" onClick={onClose}>
						Отменить
					</Button>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						onClick={form.handleSubmit(onSubmit)}
						isLoading={isLoading}
					>
						{variant === 'edit' ? 'Сохранить' : 'Добавить сборочную единицу'}
					</Button>
				</ButtonGroup>
			}
			onBlur={onClose}
		>
			<FormProvider {...form}>
				<AssemblyForm />
			</FormProvider>
		</SideModal>
	);
};
