import { selectEmployeesInitials } from '@/entities/employee';
import { useTechCard } from '@/shared';
import { convertNumberToNumberStringWithComma } from '@/shared/core/utils/convert-string-to-number-string';
import { useGetAllInitialsQuery, useGetPreformTechMapByNumberQuery } from '@/shared/state/api/swagger';
import { Flex, Input, Table, Td, Th, Tr, Text } from '@chakra-ui-kraud/react';
import { Select } from 'antd';
import clsx from 'clsx';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FormPreformModel } from '../../model/preform.types';
import styles from './plan.module.scss';
import { PlanBlockPreformTypes } from './plan.types';

export const PlanPreform: FC<PlanBlockPreformTypes> = ({ isPrinting, isEditable }) => {
	const { isCreationMode } = useTechCard();
	const mapNumber = Number(useParams().number);
	const { control, setValue } = useFormContext<FormPreformModel>();
	const { options: employee } = useGetAllInitialsQuery(
		{ stringToSearch: '' },
		{
			selectFromResult: (result) => ({ ...result, options: selectEmployeesInitials(result?.data) }),
		},
	);
	const { data: preformMap } = useGetPreformTechMapByNumberQuery(
		{ preformTechMapNumber: mapNumber },
		{
			skip: !mapNumber || Number.isNaN(mapNumber),
		},
	);

	return (
		<Flex direction="column" flex={'1'} className={styles['plan-preform']}>
			<Table marginBottom={'-1px'}>
				<Tr>
					<Th minW="160px" maxW="320px">
						Выдано, м X шт.
					</Th>
					<Th w="120px">Подпись</Th>
					<Th minW="160px" maxW="320px">
						ФИО выдавшего материал
					</Th>
				</Tr>
				<Tr>
					<Td>
						{isCreationMode || (isEditable && !isPrinting) ? (
							<Controller
								name="preform_plan.issuance_count"
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										backgroundColor="transparent"
										tabIndex={5}
										isInvalid={!!error}
										onChange={onChange}
										value={convertNumberToNumberStringWithComma(
											String(value).replace(/[^0-9,.хx]/g, ''),
										)}
									/>
								)}
							/>
						) : (
							<Text className={styles['print_bold_font']}>
								{preformMap?.preform_plan?.issuance_count || ''}
							</Text>
						)}{' '}
					</Td>
					<Td></Td>
					<Td>
						{isEditable ? (
							<Controller
								name="preform_plan.issuance_fio"
								control={control}
								render={({ field: { value, onChange }, fieldState: { error } }) => (
									<Select
										status={error ? 'error' : ''}
										allowClear
										tabIndex={5}
										filterOption={(input, option) =>
											((option?.label ?? '') as string)
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										showSearch
										placeholder="Выберите ФИО"
										optionFilterProp="children"
										onChange={(e) => {
											onChange(e);
											setValue(
												'preform_plan.issuance_fio',
												e ? employee?.find((el) => el.value === Number(e))?.label || '' : '',
											);
										}}
										value={value}
										options={employee}
										style={{
											whiteSpace: 'nowrap',
											width: '100%',
											boxShadow: error?.message ? '0 0 0 1px #fc5e6e' : '',
										}}
										className={styles['custom-select']}
									/>
								)}
							/>
						) : (
							<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
								{preformMap?.preform_plan?.issuance_fio || ''}
							</Text>
						)}
					</Td>
				</Tr>
				<Tr>
					<Th minW="160px" maxW="320px">
						Возврат, м X шт.
					</Th>
					<Th w="120px">Подпись</Th>
					<Th minW="160px" maxW="320px">
						ФИО принявшего материал
					</Th>
				</Tr>
				<Tr>
					<Td>
						{isCreationMode || (isEditable && !isPrinting) ? (
							<Controller
								name="preform_plan.return_count"
								control={control}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<Input
										backgroundColor="transparent"
										tabIndex={5}
										isInvalid={!!error}
										onChange={onChange}
										value={convertNumberToNumberStringWithComma(
											String(value).replace(/[^0-9,.хx]/g, ''),
										)}
										name="preform_plan.return_count"
									/>
								)}
							/>
						) : (
							<Text className={styles['print_bold_font']}>
								{preformMap?.preform_plan?.return_count || ''}
							</Text>
						)}{' '}
					</Td>
					<Td></Td>
					<Td>
						{isEditable ? (
							<Controller
								name="preform_plan.return_fio"
								control={control}
								render={({ field: { value, onChange } }) => (
									<Select
										allowClear
										tabIndex={5}
										filterOption={(input, option) =>
											((option?.label ?? '') as string)
												.toLowerCase()
												.includes(input.toLowerCase())
										}
										showSearch
										placeholder="Выберите ФИО"
										optionFilterProp="children"
										onChange={(e) => {
											onChange(e);
											setValue(
												'preform_plan.return_fio',
												e ? employee?.find((el) => el.value === Number(e))?.label : '',
											);
										}}
										value={value}
										options={employee}
										style={{ whiteSpace: 'nowrap', width: '100%' }}
										className={styles['custom-select']}
									/>
								)}
							/>
						) : (
							<Text className={clsx(styles['text-in-cell'], styles['print_bold_font'])}>
								{preformMap?.preform_plan?.return_fio || ''}
							</Text>
						)}
					</Td>
				</Tr>
			</Table>
		</Flex>
	);
};
