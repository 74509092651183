import { CellTableParagraph } from '@/shared';
import { CounteragentResponse } from '@/shared/state/api/swagger';
import { ColumnsType } from 'antd/es/table';

const onHeaderCell = () => {
	return {
		style: {
			width: '100%',
			whiteSpace: 'normal',
		},
	};
};

export const COUNTER_AGENT_TABLE_COLUMNS: ColumnsType<CounteragentResponse> = [
	{
		key: 'name',
		title: 'Организация',
		dataIndex: 'name',
		sorter: true,
		onHeaderCell,
		align: 'left',
		sortDirections: ['ascend', 'descend'],
		render: (value) => <CellTableParagraph value={value} />,
	},
];
