import { FC, useCallback } from 'react';
import type { OperationsAssemblyBlockTypes } from '../model/operations.types';

import classnames from 'classnames';
import { clsx } from 'clsx';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { chakra, Input, Table, Td, Text, Th, Thead, Tr } from '@chakra-ui-kraud/react';
import { DatePicker, Select } from 'antd';

import { AssemblyOperationResponse, useGetAllExecutorsQuery } from '@/shared/state/api/swagger';
import { COMPLECT_OPERATION_STEP_ID, DEFAULT_YEAR_FORMAT, useTechCard } from '@/shared';

import styles from './default-operations.module.scss';

import { convertNumberToNumberStringWithDot } from '@/shared/core/utils/convert-string-to-number-string';
import { AssemblyConfig } from '@/widgets/tech-card/form-assembly-config';
import { selectExecutorsForOptions } from '@/entities/employee';

export const DefaultOperationsAssemblyBlock: FC<OperationsAssemblyBlockTypes> = ({ isEditable }) => {
	const { assemblyCard: cardInfo, isCreationMode } = useTechCard();

	const { control, setValue, getValues } = useFormContext<AssemblyConfig>();

	const { assembly_operations: fields } = getValues();

	const { options: employee } = useGetAllExecutorsQuery(
		{ stringToSearch: '' },
		{
			selectFromResult: (result) => ({
				...result,
				options: selectExecutorsForOptions(result?.data),
			}),
		},
	);

	const sortedOperationsTypes = fields ? [...fields].sort((a, b) => Number(a?.step_id) - Number(b?.step_id)) : [];

	// определение завершенной операции (если есть ФИО и дата)
	const isOperationDone = useCallback((operation: AssemblyOperationResponse) => {
		if (
			!!operation.fio?.length &&
			!!operation.date?.length &&
			!!operation.count_in_number &&
			!!operation.count_out_number
		) {
			return true;
		}
		return false;
	}, []);

	// определение завершенной операции (если есть ФИО и дата) и пустого поля
	const isOperationDoneAndEmptyField = useCallback(
		(operation: AssemblyOperationResponse, fieldValue: string | number | null | undefined) => {
			if (isOperationDone(operation) && !fieldValue) {
				return true;
			}
			return false;
		},
		[isOperationDone],
	);

	const customIdx = (cardInfo?.assembly_operations?.length || 0) - 2;
	const defaultOperations = cardInfo?.assembly_operations?.filter((operation) => operation.is_default);

	return (
		<Table>
			<Thead display="table-header-group">
				<Th style={{ verticalAlign: 'middle' }} w="509px">
					Наименование операции
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="296px">
					Дата
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="150px">
					Годных штук
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="358px">
					ФИО
				</Th>
				<Th style={{ verticalAlign: 'middle' }} w="531px">
					Подпись
				</Th>
			</Thead>

			{!isEditable && !isCreationMode && (
				<>
					{defaultOperations?.map((operation, index) => (
						<Tr key={operation.id} className={clsx(operation.is_canceled ? styles['field-disabled'] : '')}>
							<Td style={{ textAlign: 'left' }}>
								<chakra.div>
									{operation.name}
									{operation.is_canceled ? '— операция отменена' : ''}
								</chakra.div>
							</Td>
							<Td>
								{dayjs(operation.date).isValid()
									? dayjs(operation.date).format(DEFAULT_YEAR_FORMAT)
									: ''}
							</Td>
							<Td>
								{!isOperationDoneAndEmptyField(operation, operation.count_out_number)
									? operation.count_out_number
									: '-'}
							</Td>
							<Td>{operation.fio}</Td>
							<Td></Td>
						</Tr>
					))}
				</>
			)}
			{!isEditable && isCreationMode && sortedOperationsTypes?.length ? (
				<>
					{['Сдано на СГД', 'Принято на СГД'].map((operation, idx) => (
						<Tr key={idx}>
							<Td style={{ textAlign: 'left' }}>{operation}</Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
							<Td></Td>
						</Tr>
					))}
				</>
			) : (
				<></>
			)}

			{isEditable &&
				!isCreationMode &&
				defaultOperations?.map((field, idx) => {
					const index = customIdx + idx;

					return (
						<Tr key={field.id}>
							<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
								<Text padding={isEditable ? '8px 1px 8px 8px' : ''} fontWeight="normal">
									{field.name}
								</Text>
							</Td>
							<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
								{!field.is_canceled ? (
									<Controller
										name={`assembly_operations.${index}.date`}
										control={control}
										render={({ field: { onChange }, fieldState: { error } }) => (
											<DatePicker
												style={{ width: '100%' }}
												tabIndex={7}
												status={error ? 'error' : ''}
												onChange={onChange}
												defaultValue={
													field.date && dayjs(field.date).isValid()
														? dayjs(field.date)
														: undefined
												}
												format={DEFAULT_YEAR_FORMAT}
												name={`assembly_operations.${index}.date`}
											/>
										)}
									/>
								) : (
									<Text p="6px" fontWeight="normal" textAlign="left">
										{field.date ? dayjs(field.date).format(DEFAULT_YEAR_FORMAT) : ''}
									</Text>
								)}
							</Td>
							<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
								{!field.is_canceled ? (
									<Controller
										name={`assembly_operations.${index}.count_out_number`}
										control={control}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<Input
												backgroundColor="transparent"
												tabIndex={7}
												isInvalid={!!error}
												onChange={(e) => {
													onChange(e);
													if (index === customIdx) {
														// перенос значения из предпоследней дефолтной операции в последнюю кастомную
														// шт., поля Сдача на СГД -> раздел Кол-во, Консервация
														if (
															getValues(
																`assembly_operations.${customIdx - 1}.step_id`,
															) === COMPLECT_OPERATION_STEP_ID
														) {
															return;
														}

														setValue(
															`assembly_operations.${customIdx - 1}.count_in_number`,
															Number(convertNumberToNumberStringWithDot(e.target.value)),
														);
													}
													if (!e.target.value) {
														setValue(`assembly_operations.${index}.wasted`, undefined);
													} else {
														setValue(
															`assembly_operations.${index}.wasted`,
															Number(
																getValues(
																	`assembly_operations.${index}.count_in_number`,
																),
															) - Number(e.target.value),
														);
													}
												}}
												value={value}
												name={`assembly_operations.${index}.count_out_number`}
												className={field.is_canceled ? styles['field-disabled'] : ''}
												disabled={field.is_canceled}
											/>
										)}
									/>
								) : (
									<Text
										p="6px"
										fontWeight="normal"
										textAlign={
											!isOperationDoneAndEmptyField(field, field.count_out_number)
												? 'left'
												: 'center'
										}
									>
										{!isOperationDoneAndEmptyField(field, field.count_out_number)
											? field.count_out_number
											: '-'}
									</Text>
								)}
							</Td>
							<Td className={field.is_canceled ? styles['field-disabled'] : ''}>
								{!field.is_canceled ? (
									<Controller
										name={`assembly_operations.${index}.fio`}
										control={control}
										render={({ field: { value, onChange } }) => (
											<Select
												allowClear
												tabIndex={7}
												filterOption={(input, option) =>
													(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
												}
												showSearch
												placeholder="Выберите ФИО"
												optionFilterProp="children"
												value={value}
												onChange={(e) => {
													onChange(e);
													setValue(
														`assembly_operations.${index}.fio`,
														e ? employee?.find((el) => el.value === e)?.label : '',
													);
												}}
												options={employee}
												style={{ width: '100%' }}
												className={classnames(styles['custom-select'])}
											/>
										)}
									/>
								) : (
									<Text p="6px" fontWeight="normal" textAlign="center">
										{field.fio}
									</Text>
								)}
							</Td>
							<Td></Td>
						</Tr>
					);
				})}
		</Table>
	);
};
