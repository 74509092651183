import { chakra, Spinner } from '@chakra-ui-kraud/react';
import { MadIcon } from 'madsoft-icons';
import { FC } from 'react';
import { CommentBadgeProps } from '../model/tech-map.types.';
import styles from './comment-badge.module.scss';
import { useGetProductTechMapCommentsQuery } from '@/shared/state/api/swagger';

export const CommentBadge: FC<CommentBadgeProps> = ({ isLoading, count }) => {
	return (
		<>
			{count ? (
				<chakra.div className={styles['badge']}>
					<chakra.p className={styles['badge_count']}>{count}</chakra.p>
				</chakra.div>
			) : (
				<>
					{isLoading ? (
						<Spinner size={'sm'} />
					) : (
						<MadIcon module="basic" mode="default" size="default" type="outline" name="chat" />
					)}
				</>
			)}
		</>
	);
};
