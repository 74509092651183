import { DetailsIssuedAlert, ErrorFillOperationAlert } from '@/entities';
import { CalendarSinglePicker, CustomInput, useAppSelector, CustomSelect } from '@/shared';
import styles from '@/shared/components/custom-select/custom-select.module.scss';
import {
	ProductSgdOutCreateRequest,
	ProductSgdOutUpdateRequest,
	usePutSgdOutOperatorMutation,
} from '@/shared/state/api/swagger';
import { selectCurrentUserId } from '@/shared/state/slices';
import { useOperationNotification } from '@/widgets/operation/hooks';
import { formatDate } from '@/widgets/operation/utils';
import { Button, chakra } from '@chakra-ui-kraud/react';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IssueDetailsFormProps } from '../../../model/operation.types';
import { issueDetailsSchema } from '../../../model/operations.schema';
import { OperationLayout } from '../../operation-layout/operation-layout';
import { OperationFormHeader } from '../form-header/form-header';

dayjs.extend(utc);
dayjs.extend(timezone);

type IssueDetailsFormValues = Omit<ProductSgdOutCreateRequest, 'fio'> & {
	fio: string | number;
};

export const IssueDetailsForm = ({ techMap, mappedEmployees }: IssueDetailsFormProps) => {
	const [groupVisibility, setGroupVisibility] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const currentUserId = useAppSelector(selectCurrentUserId);
	const { showOperationToast } = useOperationNotification();
	const [putSgd, { isSuccess }] = usePutSgdOutOperatorMutation();

	const { control, handleSubmit } = useForm<IssueDetailsFormValues>({
		resolver: yupResolver(issueDetailsSchema),
		defaultValues: {
			fio:
				mappedEmployees.find((employee) => Number(employee.value.split('_')[0]) === currentUserId)?.value ??
				undefined,
		},
	});

	const onSubmit = (data: IssueDetailsFormValues) => {
		if (!techMap?.id || !data.date || !data?.count || !techMap?.remaining_count || !techMap.product_sgd_out) return;

		if (data?.count > techMap?.remaining_count) {
			showOperationToast({ status: 'warning', type: 'over_count', remainingCount: techMap.remaining_count });
			return;
		}
		setIsFetching(true);

		const date = formatDate(data?.date);
		const fio = mappedEmployees.find(
			(employee) => Number(employee.value.split('_')[0]) === Number(data.fio),
		)?.label;
		const createPayload: ProductSgdOutCreateRequest[] = [
			{
				...data,
				date,
				product_tech_map_id: techMap?.id,
				fio,
			},
		];

		const updatePayload = techMap.product_sgd_out.map((sgd) => ({
			...sgd,
			product_tech_map_id: techMap.id,
		}));

		putSgd({
			bodyPutSgdOutOperator: {
				create_payload: createPayload,
				update_payload: updatePayload as ProductSgdOutUpdateRequest[],
			},
		})
			.unwrap()
			.catch((err) => {
				console.error(err);
				setIsError(true);
			})
			.finally(() => setIsFetching(false));
	};

	if (isError) {
		return (
			<ErrorFillOperationAlert
				handleCompleteOperationAgain={() => setIsError(false)}
				productTechMapNumber={techMap?.number}
			/>
		);
	}
	if (isSuccess) return <DetailsIssuedAlert productTechMapNumber={techMap?.number} />;

	return (
		<OperationLayout
			header={<OperationFormHeader techMap={techMap} />}
			footer={
				<Button width="100%" onClick={handleSubmit(onSubmit)} isDisabled={isFetching}>
					Сохранить
				</Button>
			}
		>
			<chakra.div display="flex" flexDirection="column" gap="20px">
				<Controller
					name="date"
					control={control}
					render={({ field: { onChange } }) => (
						<CalendarSinglePicker
							clearable={false}
							defaultDate={dayjs().format('YYYY-MM-DD')}
							label="Дата упаковки и консервации"
							onSelectDate={(date) => onChange(date)}
						/>
					)}
				/>
				<Controller
					name="count"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							type="number"
							size="md"
							{...field}
							isInvalid={!!error}
							label="Укажите количество"
							showTooltip={!!error?.message}
							tooltipContent={error?.message}
						/>
					)}
				/>
				<Controller
					name="receiver"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							size="md"
							{...field}
							isInvalid={!!error}
							label="Укажите получателя"
							showTooltip={!!error?.message}
							tooltipContent={error?.message}
						/>
					)}
				/>
				<Controller
					name="certificate"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<CustomInput
							size="md"
							{...field}
							isInvalid={!!error}
							label="Укажите спецификацию"
							showTooltip={!!error?.message}
							tooltipContent={error?.message}
						/>
					)}
				/>
				<Controller
					name="fio"
					control={control}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<CustomSelect
							onFocus={() => setGroupVisibility(true)}
							onBlur={() => setGroupVisibility(false)}
							showSearch
							allowClear={false}
							options={mappedEmployees}
							value={value}
							defaultValue={mappedEmployees.find((employee) => employee.value === value)?.label}
							onChange={(e) => onChange(e)}
							filterOption={(input, option) =>
								String(option?.label ?? '')
									.toLowerCase()
									.includes(input.toLowerCase())
							}
							optionFilterProp="children"
							dropdownStyle={{ zIndex: 1400 }}
							size="large"
							label="ФИО выдавшего"
							className={clsx(
								styles['select'],
								styles['select-large'],
								groupVisibility && styles['select-focus'],
								error && [styles['select-invalid']],
							)}
						/>
					)}
				/>
			</chakra.div>
		</OperationLayout>
	);
};
