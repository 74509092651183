import { CounterAgentFormModal } from '@/features/counter-agent-form';
import { DEFAULT_PAGE_SIZE, Loader, MainContentLoader, Search, useAppSelector, UserRoles } from '@/shared';
import { CounteragentResponse, GetCounteragentsApiArg, useGetCounteragentsQuery } from '@/shared/state/api/swagger';
import { selectUserRole } from '@/shared/state/slices';
import { Layout } from '@/widgets';
import { CounterAgentTable } from '@/widgets/counter-agent-table/ui/counter-agent-table';
import { Button, TextHeader, useDisclosure } from '@chakra-ui-kraud/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import _debounce from 'lodash/debounce';
import { useCallback, useState } from 'react';

export const CounterAgentPage = () => {
	const userRole = useAppSelector(selectUserRole);
	const [selectedAgent, setSelectedAgent] = useState<CounteragentResponse | null>(null);
	const [variant, setVariant] = useState<'create' | 'edit'>('create');
	const [searchValue, setSearchValue] = useState('');
	const debouncedSearch = useCallback(
		_debounce((e: React.ChangeEvent<HTMLInputElement>) => {
			setQueryParams((prev) => ({
				...prev,
				stringToSearch: e.target.value.trim(),
				currentPage: 1,
			}));
		}, 200),
		[],
	);
	const [queryParams, setQueryParams] = useState<GetCounteragentsApiArg>({
		stringToSearch: undefined,
		pageSize: 32,
		currentPage: 1,
		orderBy: undefined,
		orderDir: undefined,
	});

	const { isOpen, onOpen, onClose: closeModal } = useDisclosure();
	const onClose = () => {
		setSelectedAgent(null);
		closeModal();
	};

	const { data, isLoading, isFetching } = useGetCounteragentsQuery(queryParams);

	return (
		<>
			<Layout
				search={
					<Search
						handleChange={(e) => {
							debouncedSearch(e);
							setSearchValue(e.target.value);
						}}
						value={searchValue}
					/>
				}
				title={
					<TextHeader variant="h1" className="h1">
						Справочник контрагентов
					</TextHeader>
				}
				headerLeftButton={
					userRole !== UserRoles.user && (
						<Button
							size="md"
							variant="ghost"
							colorScheme="primary"
							lineHeight="24px"
							rightIcon={<ChevronRightIcon />}
							onClick={() => {
								setSelectedAgent(null);
								setVariant('create');
								onOpen();
							}}
						>
							Добавить контрагента
						</Button>
					)
				}
			>
				{isFetching && !isLoading ? <MainContentLoader /> : <></>}
				{isLoading ? (
					<Loader />
				) : (
					<CounterAgentTable
						setQueryParams={setQueryParams}
						pageSize={queryParams.pageSize ?? DEFAULT_PAGE_SIZE}
						isLoading={isLoading}
						total={data?.total}
						data={data?.payload || []}
						page={data?.page}
						handleClick={(agent, variant) => {
							onOpen();
							setVariant(variant);
							setSelectedAgent(agent);
						}}
					/>
				)}
			</Layout>
			{isOpen && (
				<CounterAgentFormModal
					isOpen={isOpen}
					onClose={onClose}
					variant={variant}
					selectedAgent={selectedAgent}
				/>
			)}
		</>
	);
};
