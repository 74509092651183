import {
	EMPLOYEES,
	NUM_CATALOG,
	PRODUCT_CATALOG,
	TECH_CARDS_PATH,
	USERS,
	ISSUES_PATH,
	ASSEMBLY_PATH,
	PREFORM_PATH,
	COUNTER_AGENT_PATH,
} from '@/shared/core';

export enum NavButtonNames {
	TECH_CARD = 'buttonTechCard',
	NUM_CATALOG = 'buttonNumCatalog',
	PRODUCT_CATALOG = 'buttonProductCatalog',
	USERS = 'buttonUsers',
	EMPLOYEES = 'employees',
	ISSUES = 'issues',
	ASSEMBLY = 'assembly',
	PREFORM = 'preform',
	COUNTER_AGENT = 'counter-agent',
}

export const activeButtonMatcher: { [path: string]: NavButtonNames } = {
	[TECH_CARDS_PATH]: NavButtonNames.TECH_CARD,
	[NUM_CATALOG]: NavButtonNames.NUM_CATALOG,
	[PRODUCT_CATALOG]: NavButtonNames.PRODUCT_CATALOG,
	[USERS]: NavButtonNames.USERS,
	[EMPLOYEES]: NavButtonNames.EMPLOYEES,
	[ISSUES_PATH]: NavButtonNames.ISSUES,
	[ASSEMBLY_PATH]: NavButtonNames.ASSEMBLY,
	[PREFORM_PATH]: NavButtonNames.PREFORM,
	[COUNTER_AGENT_PATH]: NavButtonNames.COUNTER_AGENT,
	['/']: NavButtonNames.TECH_CARD,
};
