import { FC, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { diff } from 'deep-object-diff';

import { abbreviateUser, IUserForm } from '@/shared/core';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserFormProps, UserFormData } from '../model/user-form.types';

import { Button, chakra, Stack, TextHeader } from '@chakra-ui-kraud/react';
import { CustomInput, CustomSelect, FormDatepicker, selectRolesOptionsForAdminCreate } from '@/shared';

import styles from './user-form.module.scss';
import { UserFormRequirements } from './user-form-requirements';
import { UserFormPassword } from './user-form-password';
import { useGetUserRolesMappingQuery } from '@/shared/state/api/swagger';
import { format } from 'date-fns';
import { userFormSchema } from '@/features/user-forms/model/user-form.schema';
import { isEmpty } from 'lodash';

export const UserForm: FC<UserFormProps> = ({
	reference,
	onSubmit,
	selectedUser,
	isChangePasswordModalOpen = false,
	variant,
	setFormWasEdit,
	setChangePasswordModalOpen,
}) => {
	const [passIsRequired] = useState(variant === 'create');

	const schema = useMemo(
		() => userFormSchema(passIsRequired, isChangePasswordModalOpen),
		[passIsRequired, isChangePasswordModalOpen],
	);

	useImperativeHandle(reference, () => ({
		submitForm() {
			handleSubmit((data) => {
				const changedV: IUserForm = diff(selectedUser ?? {}, data);
				if (Object.keys(changedV)?.length) {
					if (passIsRequired && !isChangePasswordModalOpen) {
						onSubmit({
							...changedV,
							id: data.id,
						});
						return;
					}
					if (passIsRequired || isChangePasswordModalOpen) {
						onSubmit({
							id: data.id,
							password: changedV?.password,
						});
						return;
					}
					if (!passIsRequired && !isChangePasswordModalOpen) {
						onSubmit({
							...changedV,
							id: data.id,
							password: undefined,
						});
						return;
					}
				}
				return;
			})();
		},
	}));

	const formProps = useForm<UserFormData>({
		defaultValues: {
			...selectedUser,
		},
		resolver: yupResolver(schema),
	});
	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors, isDirty, isValid },
	} = formProps;

	const { rolesOptions, isFetching: isRolesFetching } = useGetUserRolesMappingQuery(undefined, {
		selectFromResult: (result) => ({
			...result,
			rolesOptions: selectRolesOptionsForAdminCreate(result.data),
		}),
	});

	const fio = abbreviateUser(
		selectedUser?.first_name ?? '',
		selectedUser?.last_name ?? '',
		selectedUser?.middle_name ?? '',
	);
	const { password } = useWatch({ control });

	const getChangePasswordModalMargin = () => {
		// return `${Number(headerWidth) + Number(footerWidth) + 32}px`;
		return '200px';
	};
	useEffect(() => {
		if (isDirty && isValid) {
			setFormWasEdit && setFormWasEdit(true);
		}
	}, [isDirty, isValid, schema, setFormWasEdit]);

	return (
		<FormProvider {...formProps}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles['user-form']}
				style={{ position: `${isChangePasswordModalOpen ? 'relative' : 'static'}` }}
			>
				{!isChangePasswordModalOpen ? (
					<>
						<Controller
							control={control}
							name="last_name"
							render={({ field, fieldState: { error } }) => (
								<CustomInput
									size="md"
									{...field}
									isInvalid={!!error}
									label="Фамилия"
									defaultValue={selectedUser?.last_name}
									showTooltip={!!errors.last_name?.message}
									tooltipContent={errors.last_name?.message}
								/>
							)}
						/>
						<Controller
							control={control}
							name="first_name"
							render={({ field, fieldState: { error } }) => (
								<CustomInput
									size="md"
									{...field}
									isInvalid={!!error}
									label="Имя"
									defaultValue={selectedUser?.first_name}
									showTooltip={!!errors.first_name?.message}
									tooltipContent={errors.first_name?.message}
								/>
							)}
						/>
						<Controller
							control={control}
							name="middle_name"
							render={({ field, fieldState: { error } }) => (
								<CustomInput
									size="md"
									{...field}
									isInvalid={!!error}
									label="Отчество"
									defaultValue={selectedUser?.middle_name}
									showTooltip={!!errors.middle_name?.message}
									tooltipContent={errors.middle_name?.message}
								/>
							)}
						/>
						<Controller
							control={control}
							name="custom_initials"
							render={({ field, fieldState: { error } }) => (
								<CustomInput
									size="md"
									{...field}
									isInvalid={!!error}
									label="Инициалы"
									defaultValue={selectedUser?.custom_initials}
									showTooltip={!!errors.custom_initials?.message}
									tooltipContent={errors.custom_initials?.message}
								/>
							)}
						/>
						<Controller
							control={control}
							name="position"
							render={({ field, fieldState: { error } }) => (
								<CustomInput
									size="md"
									{...field}
									isInvalid={!!error}
									label="Должность"
									defaultValue={selectedUser?.position}
									showTooltip={!!errors.position?.message}
									tooltipContent={errors.position?.message}
								/>
							)}
						/>
						<Controller
							control={control}
							name="date_of_work_start"
							render={({ field: { onChange, ref, ...fieldProps } }) => (
								<FormDatepicker
									inputLabel="Дата начала работы"
									defaultDate={selectedUser?.date_of_work_start ?? format(new Date(), 'yyyy-MM-dd')}
									onSelectDate={onChange}
									clearable={false}
									{...fieldProps}
									value={undefined}
								/>
							)}
						/>
						<Controller
							control={control}
							name="role"
							render={({ field, fieldState: { error } }) => (
								<CustomSelect
									onClear={() => setValue('role', undefined)}
									tooltipContent={errors.role?.message}
									showTooltip={!!errors.role?.message}
									initialValue={undefined}
									label="Роль"
									options={rolesOptions}
									isLoading={isRolesFetching}
									defaultValue={selectedUser?.role}
									isInvalid={!!error}
									showSearch={false}
									{...field}
								/>
							)}
						/>
						<Controller
							control={control}
							name="email"
							render={({ field, fieldState: { error } }) => (
								<CustomInput
									type="email"
									size="md"
									{...field}
									isInvalid={!!error}
									label="Почта"
									defaultValue={selectedUser?.email}
									showTooltip={!!errors.email?.message}
									tooltipContent={errors.email?.message}
								/>
							)}
						/>
					</>
				) : null}
				{variant === 'edit' && !isChangePasswordModalOpen ? (
					<>
						<Button
							marginTop={'12px'}
							size="md"
							variant="outline"
							colorScheme="tertiary"
							onClick={() => {
								setChangePasswordModalOpen && setChangePasswordModalOpen(true);
							}}
						>
							Изменить пароль пользователя
						</Button>
					</>
				) : !isChangePasswordModalOpen ? (
					<>
						<UserFormPassword selectedUser={selectedUser} errors={errors} />{' '}
						<UserFormRequirements password={password} />
					</>
				) : null}
				{isChangePasswordModalOpen ? (
					<div
						className={styles['change-pass-wrapper']}
						style={{
							height: `calc(100vh - ${getChangePasswordModalMargin()})`,
						}}
					>
						<Stack gap={1} pb={5}>
							<TextHeader fontWeight={'semibold'} variant="h5">
								{fio}
							</TextHeader>{' '}
							<chakra.p fontSize={'xs'} mt="0px !important" fontWeight={'normal'} color={'gray.500'}>
								{selectedUser?.email}
							</chakra.p>
						</Stack>
						<UserFormPassword selectedUser={selectedUser} errors={errors} />{' '}
						<UserFormRequirements password={password} />
					</div>
				) : null}
			</form>
		</FormProvider>
	);
};
