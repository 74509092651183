import { NoCommentsAlert } from '@/entities';
import { Comment, SideModal } from '@/shared';
import { Button, Flex, Spinner, Textarea } from '@chakra-ui-kraud/react';
import { FC, useState } from 'react';
import { TechCardCommentsModalProps } from './tech-card-comments-modal.types';

export const TechCardCommentsModal: FC<TechCardCommentsModalProps> = ({
	isOpen,
	onClose,
	data: comments,
	title = 'Комментарии',
	onCreateComment,
	isCreateCommentLoading,
	isCommentsLoading,
	onChangeComment,
	isChangeCommentLoading,
	onDeleteComment,
	isDeleteCommentLoading,
}) => {
	const [createCommentText, setCreateCommentText] = useState('');

	return (
		<SideModal
			title={title}
			isOpen={isOpen}
			onClose={onClose}
			footer={
				<Flex w="100%" direction="column" gap={3}>
					<Textarea
						autoFocus
						placeholder="Комментарий..."
						value={createCommentText}
						onChange={(e) => setCreateCommentText(e.target.value)}
						resize="none"
						fontSize="xs"
					/>
					<Button
						size="md"
						variant="solid"
						colorScheme="primary"
						isDisabled={createCommentText === ''}
						isLoading={isCreateCommentLoading}
						onClick={() => onCreateComment(createCommentText).then(() => setCreateCommentText(''))}
					>
						Добавить комментарий
					</Button>
				</Flex>
			}
		>
			{isCommentsLoading ? (
				<Spinner margin="50%" />
			) : comments?.length ? (
				comments?.map(({ text, created_at, id, user, able_edit, techMapId }) => (
					<Comment
						techMapId={techMapId}
						key={id}
						commentId={id}
						author={user}
						text={text}
						created_at={created_at}
						ableEdit={able_edit}
						onChangeComment={onChangeComment}
						isChangeCommentLoading={isChangeCommentLoading}
						onDeleteComment={onDeleteComment}
						isDeleteCommentLoading={isDeleteCommentLoading}
					/>
				))
			) : (
				<NoCommentsAlert noBackground={true} />
			)}
		</SideModal>
	);
};
