import { useCallback, useEffect, useState } from 'react';
import { RouteMatch, useLocation, useNavigate, useParams } from 'react-router-dom';
import { diff } from 'deep-object-diff';
import { isEmpty, isEqual } from 'lodash';
import dayjs from 'dayjs';
import { useToast } from '@chakra-ui-kraud/react';

import {
	LINK_TO_TECH_CARD_ASSEMBLY_INFO,
	LINK_TO_TECH_CARD_INFO,
	TechCardStatuses,
	TECH_CARDS_PATH,
	TECH_CARD_ASSEMBLY_EDIT,
	TECH_CARD_ASSEMBLY_INFO,
	TECH_CARD_ASSEMBLY_NEW,
	TECH_CARD_ASSEMBLY_ROOT,
	TECH_CARD_EDIT,
	TECH_CARD_INFO,
	TECH_CARD_NEW,
	TECH_CARD_PREFORM_NEW,
	TECH_CARD_PREFORM_INFO,
	TECH_CARD_PREFORM_EDIT,
	UserRoles,
	TECH_CARD_PREFORM_PATH,
	StandardConsumptionTypeEnum,
} from '../core';
import { useAppSelector } from '../state';
import { DEFAULT_YEAR_FORMAT, showErrorToast, showSuccessToast } from '@/shared';

import { getTechCardName } from '../core/utils/tech-card-utils';
import { convertNumberToNumberStringWithDot } from '../core/utils/convert-string-to-number-string';
import {
	ProductTechMapResponse,
	ProductSgdOutDomain,
	useCreateOperationMutation,
	useGetTechMapByNumberQuery,
	useUpdateTechMapMutation,
	useCancelTechmapMutation,
	usePutSgdOutMutation,
	usePostSgdOutMutation,
	usePutSgdOutOperatorMutation,
	usePutCustomProductMutation,
	usePutPlanMutation,
	usePatchPlanOperatorMutation,
	useUpdateOperationsMutation,
	useUpdateOperationsOperatorMutation,
	useCancelOperationMutation,
	useRenewalOperationMutation,
	useUpdateNumMutation,
	usePutCustomMaterialMutation,
	AssemblyTechMapResponse,
	useUpdateAssemblyTechMapMutation,
	useGetAssemblyTechMapsQuery,
	useCancelAssemblyTechMapMutation,
	AssemblySgdOutCreateRequest,
	BackendHttpValidationError,
} from '../state/api/swagger';
import { FormT } from '@/pages/tech-card-page/formConfig';
import { AssemblyConfig } from '@/widgets/tech-card/form-assembly-config';
import { matchRoutes } from 'react-router-dom';
import { UseFormReturn } from 'react-hook-form';
import { isFetchBaseQueryError } from '../state/api';

export const useTechCard = () => {
	const toast = useToast();
	const navigate = useNavigate();

	const cardNumber = Number(useParams().number);

	const userRole = useAppSelector((state) => state.auth.userProfile?.role);
	const userId = useAppSelector((state) => state.auth.userProfile?.id);

	const location = useLocation();
	const firstPathSegment = location.pathname.split('/')[1];

	const isAssemblyTechMap = firstPathSegment === TECH_CARD_ASSEMBLY_ROOT.replace('/', '');
	const isPreformTechMap = firstPathSegment === TECH_CARD_PREFORM_PATH.replace('/', '');

	const creationRoutes = [
		{
			path: TECH_CARD_NEW,
		},
		{ path: TECH_CARD_ASSEMBLY_NEW },
		{ path: TECH_CARD_PREFORM_NEW },
	];
	const viewRoutes = [
		{
			path: TECH_CARD_INFO,
		},
		{ path: TECH_CARD_ASSEMBLY_INFO },
		{ path: TECH_CARD_PREFORM_INFO },
	];
	const editRoutes = [
		{
			path: TECH_CARD_EDIT,
		},
		{ path: TECH_CARD_ASSEMBLY_EDIT },
		{ path: TECH_CARD_PREFORM_EDIT },
	];

	const isRouteMatched = (match: RouteMatch[] | null) => !!(match && match.length);

	const isCreationMode = isRouteMatched(matchRoutes(creationRoutes, location.pathname)) && !cardNumber;
	const isInitialEditMode = isRouteMatched(matchRoutes(editRoutes, location.pathname)) && !!cardNumber;
	const isViewMode = isRouteMatched(matchRoutes(viewRoutes, location.pathname)) && !!cardNumber;

	const assemblyCardInfo = useGetAssemblyTechMapsQuery(
		{ number: cardNumber },
		{
			skip: !cardNumber || !isAssemblyTechMap,
			selectFromResult: (result) => {
				return {
					...result,
					data: result.data?.payload[0],
				};
			},
		},
	);
	const { data: assemblyCard, isError: isAssemblyError } = assemblyCardInfo;

	const isEditable = (isCreationMode || isInitialEditMode || !isViewMode) && userRole !== UserRoles.user;

	const [isUpdating, setIsUpdating] = useState(false);
	const [isEditMode, setIsEditMode] = useState(isEditable);

	const getCardInfo = useGetTechMapByNumberQuery(
		{ productTechMapNumber: cardNumber },
		{ skip: !cardNumber || isAssemblyTechMap || isPreformTechMap },
	);

	const [updateTechMap] = useUpdateTechMapMutation();
	const [updateAssemblyTechMap] = useUpdateAssemblyTechMapMutation();

	const [updateMaterial] = usePutCustomMaterialMutation();
	const [updateProduct] = usePutCustomProductMutation();

	const [updateNum] = useUpdateNumMutation();

	const [updatePlan] = usePutPlanMutation();
	const [updatePlanOperator] = usePatchPlanOperatorMutation();

	const [createOperation] = useCreateOperationMutation();
	const [updateOperation] = useUpdateOperationsMutation();
	const [updateOperationOperator] = useUpdateOperationsOperatorMutation();
	const [cancelOperation] = useCancelOperationMutation();
	const [renewalOperation] = useRenewalOperationMutation();

	const [updateSGDOut] = usePutSgdOutMutation();
	const [createSGDOut] = usePostSgdOutMutation();
	const [updateSGDOutOperator] = usePutSgdOutOperatorMutation();

	const [cancelTechMap] = useCancelTechmapMutation();
	const [cancelAssemblyTechMapMutation] = useCancelAssemblyTechMapMutation();

	const cardInfo = getCardInfo.data;

	const cancelAssemblyTechMap = useCallback(() => {
		cancelAssemblyTechMapMutation({ assemblyTechMapId: Number(assemblyCard?.id) })
			.unwrap()
			.then(() => {
				assemblyCardInfo.refetch();
				showSuccessToast(toast, { description: `Карта № ${getTechCardName(assemblyCard)} аннулирована` });
			})
			.catch(() => showErrorToast(toast, { description: 'При аннулировании МК произошла ошибка' }));
	}, [cancelAssemblyTechMapMutation, assemblyCard, assemblyCardInfo, toast]);

	const changeStatusToCanceled = useCallback(() => {
		cancelTechMap({
			productTechMapChangeStatusAdminRequest: {
				status: 'canceled',
				product_tech_map_id: Number(cardInfo?.id),
			},
		})
			.unwrap()
			.then(() => showSuccessToast(toast, { description: `Карта № ${getTechCardName(cardInfo)} аннулирована` }))
			.catch(() => showErrorToast(toast, { description: 'При аннулировании МК произошла ошибка' }));
	}, [cancelTechMap, cardInfo, toast]);

	const handleAssemblySubmit = async (
		initialV: Partial<AssemblyTechMapResponse>,
		formProps: AssemblyConfig,
		exitAfterSubmit: Record<'exitAfterSubmit', boolean>,
	) => {
		if (assemblyCard?.id) {
			setIsUpdating(true);

			const operations = formProps.assembly_operations?.filter((el) => el.step_id || el.name) || [];
			const operationsIds = operations.map((el) => el.id);
			const updateOperations = operations.filter((el) => el.id && operationsIds.includes(el.id));
			const createOperations = operations.filter((el) => !el.id);

			const products = formProps.assembly_products.map((product) => ({
				...product,
				product_tech_map_id: product.product_tech_map_id!,
				count: product.product_sgd_out?.count || 0,
			}));

			const sgdOutToUpdate = formProps?.assembly_sgd_out?.filter((sgd) => sgd.id) || [];
			const sgdOutToCreate = formProps?.assembly_sgd_out?.filter(
				(sgd) => !sgd.id && !Object.keys(sgd).every((key) => !sgd[key as keyof AssemblySgdOutCreateRequest]),
			);

			updateAssemblyTechMap({
				assemblyTechMapId: assemblyCard.id,
				bodyUpdateAssemblyTechMap: {
					assembly_tech_map_payload: {
						number: [UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles)
							? formProps?.number
							: undefined,
						symbol: formProps.symbol ?? '',
						plan: formProps.plan,
						approval_card: formProps.approval_card,
						tech_decision: formProps.tech_decision,
						fio_pds: formProps.fio_pds,
					},
					assembly_sgd_out_payload: {
						assembly_sgd_out_to_create: isEmpty(sgdOutToCreate) ? undefined : sgdOutToCreate,
						assembly_sgd_out_to_update: sgdOutToUpdate,
					},
					assembly_operations_payload: {
						assembly_operations_to_create:
							[
								...(createOperations || []).map((el) => ({
									...el,
									date: el.date && dayjs(el.date).format('YYYY-MM-DD'),
									wasted: el.wasted ? (el.wasted > 0 ? el.wasted : 0) : 0,
								})),
							] ?? [],
						assembly_operations_to_update: [
							...(updateOperations || []).map((el) => ({
								...el,
								id: el.id as number,
								date: el.date && dayjs(el.date).format('YYYY-MM-DD'),
								wasted: el.wasted ? (el.wasted > 0 ? el.wasted : 0) : 0,
							})),
						],
					},
					assembly_tech_map_products_payload: {
						assembly_tech_map_products_to_update: products,
					},
				},
			})
				.unwrap()
				.catch((err) => {
					if (err?.status) {
						showErrorToast(toast, {
							description: `Карта №
            ${assemblyCard?.symbol ? assemblyCard.symbol + '-' + formProps?.number : formProps?.number}
             уже существует`,
						});
					}
				})
				.finally(() => {
					if (exitAfterSubmit.exitAfterSubmit) {
						navigate(TECH_CARDS_PATH);
					}
					showSuccessToast(toast, {
						title: 'Маршрутная карта обновлена',
						description: `Вы отредактировали карту № ${formProps?.number}`,
					});

					setIsEditMode(false);
					setIsUpdating(false);

					if (!!formProps?.number && initialV?.number?.toString() !== formProps.number?.toString()) {
						navigate(LINK_TO_TECH_CARD_ASSEMBLY_INFO(Number(formProps?.number)));
					} else {
						assemblyCardInfo.refetch();
					}
				});
		}
	};

	const [submitModal, setSubmitModal] = useState<null | {
		remainder: string;
		standardConsumptionType: StandardConsumptionTypeEnum;
		onContinueSubmit: ({ force }: { force: boolean }) => void;
	}>(null);

	const handleSubmit = async (
		initialV: Partial<ProductTechMapResponse>,
		form: UseFormReturn<FormT, unknown>,
		exitAfterSubmit: Record<'exitAfterSubmit', boolean>,
		force: boolean,
	) => {
		if (cardInfo?.id) {
			const formProps = form.getValues();
			const changedV: Partial<FormT & ProductTechMapResponse> = diff(initialV, formProps);

			setIsUpdating(true);
			updateTechMap({
				productTechMapUpdateRequest: {
					number: [UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles)
						? formProps?.number
						: undefined,
					symbol: formProps.symbol ?? '',
					status: formProps.status ?? cardInfo?.status,
					created_at:
						[UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles) &&
						formProps.created_at &&
						formProps.created_at !== initialV.created_at
							? dayjs(formProps.created_at, DEFAULT_YEAR_FORMAT, true).isValid()
								? dayjs(formProps.created_at, DEFAULT_YEAR_FORMAT).format('YYYY-MM-DD 12:00:00')
								: dayjs(formProps.created_at).format('YYYY-MM-DD 12:00:00')
							: undefined,
					updated_at: formProps.updated_at ?? cardInfo?.updated_at,
					id: cardInfo?.id,
				},
			})
				.unwrap()
				.then(async () => {
					const allPromise: Promise<any>[] = [];

					if (
						cardInfo?.status === TechCardStatuses.released ||
						cardInfo?.status === TechCardStatuses.progress ||
						cardInfo?.status === TechCardStatuses.wasted ||
						(cardInfo?.status === TechCardStatuses.accepted_at_sgd && userRole === UserRoles.admin) ||
						(cardInfo?.status === TechCardStatuses.partially_issued && userRole === UserRoles.admin)
					) {
						// Сначала отправляем обновленный НУМ с указанными НР в детали для корректного прохождения валидации на BE
						if (changedV.num && cardInfo?.num?.id) {
							try {
								await updateNum({
									customNumUpdateRequest: {
										gost: changedV?.num?.gost,
										gost_na_sortament: changedV?.num?.gost_na_sortament,
										id: cardInfo.num!.id as number,
										num: changedV?.num?.num,
										number_of_melt: changedV?.num?.number_of_melt,
										date_of_manufacture: changedV?.num?.date_of_manufacture ?? undefined,
										o_v: changedV?.num?.o_v,
										o_v_or_date_of_manufacture: changedV?.num?.o_v_or_date_of_manufacture,
										replace_decision: changedV?.num?.replace_decision,
										sortament: changedV?.num?.sortament,
										symbol: changedV?.num?.symbol,
										product_plan_NR_meters: Number(formProps?.product?.standard_consumption_meters),
										product_plan_NR_square_meters: Number(
											formProps?.product?.standard_consumption_square_meters,
										),
										product_plan_NR_kg: Number(formProps?.product?.standard_consumption_kg),
										product_plan_NR_grams: Number(formProps?.product?.standard_consumption_grams),
									},
									xDisableCatalogNumRemainderCheck: force,
								}).unwrap();
								setSubmitModal(null);
							} catch (error) {
								if (isFetchBaseQueryError(error)) {
									setSubmitModal({
										remainder: (error?.data as BackendHttpValidationError)?.detail
											?.remainder as string,
										standardConsumptionType: (error?.data as BackendHttpValidationError)?.detail
											?.standard_consumption_type as StandardConsumptionTypeEnum,
										onContinueSubmit: ({ force }) => {
											handleSubmit(initialV, form, exitAfterSubmit, force);
										},
									});
									return;
								}
							}
						}

						if (changedV?.product?.material && cardInfo?.product?.material?.id) {
							allPromise.push(
								new Promise((res, rej) => {
									updateMaterial({
										customMaterialRequestUpdate: {
											gost: formProps?.product?.material?.gost,
											gost_na_sortament: formProps?.product?.material?.gost_na_sortament,
											id: cardInfo?.product?.material?.id as number,
											sortament: formProps?.product?.material?.sortament,
											symbol: formProps?.product?.material?.symbol,
										},
									})
										.unwrap()
										.then((result) => res(result))
										.catch((err) => rej(err));
								}),
							);
						}
						if (
							changedV?.product_operations ||
							changedV.default_product_operations ||
							changedV.custom_operations
						) {
							const updateOperations = [
								...(formProps?.product_operations
									?.filter((el) => el.id)
									.filter((el) => el.step_id || el.name) || []),
							];
							const createOperations = [
								...(formProps?.product_operations
									?.filter((el) => !el.id)
									.filter((el) => el.step_id || el.name) || []),
								...(formProps?.custom_operations?.filter(
									(operation) => operation.name && operation.step_id,
								) || []),
							];

							// поиск отмененных и возобновленных операций
							const findDiffOperations = () => {
								// массив отменынных операций
								const canceled: { closing_employee_fio: string; operation_id: number }[] = [];
								// массив возобновляемых операций
								const renewaled: { operation_id: number }[] = [];

								if (
									isEqual(changedV?.product_operations, formProps.product_operations) &&
									isEqual(changedV?.product_operations, formProps.product_operations)
								) {
									return { canceled, renewaled };
								} else {
									// массив новых значений в операциях (formProps.product_operations) и дефолтных операциях (formProps.default_product_operations)
									const preparedOperations = [
										...(formProps.product_operations ?? []),
										...(formProps.default_product_operations ?? []),
									];
									// массив исходных значений в операциях (formProps.product_operations) и дефолтных операциях (formProps.default_product_operations)
									const preparedInitialValuesOperations = [
										...(initialV.product_operations ?? []),
										...(initialV.default_product_operations ?? []),
									];

									// сравниваем массивы операций (product_operations) и дефолтных операций (default_product_operations) с исходными значениями
									if (preparedOperations.length) {
										preparedOperations.forEach((item) => {
											preparedInitialValuesOperations.forEach((initialOperation) => {
												if (
													item.id === initialOperation.id &&
													item.is_canceled !== initialOperation.is_canceled
												) {
													// если эта операция отменена
													if (item.is_canceled) {
														canceled.push({
															closing_employee_fio: String(item.fio),
															operation_id: Number(item.id),
														});
													} else {
														// иначе - возобновляем операцию
														renewaled.push({
															operation_id: Number(item.id),
														});
													}
												}
											});
										});
									}
								}

								return { canceled, renewaled };
							};

							const { canceled, renewaled } = findDiffOperations();

							if (canceled.length) {
								allPromise.push(
									new Promise((res, rej) => {
										return canceled.map((item) => {
											cancelOperation({
												cancelProductOperationRequest: {
													closing_employee_fio: String(item.closing_employee_fio),
													product_operation_id: Number(item.operation_id),
												},
											})
												.unwrap()
												.then((result) => res(result))
												.catch((err) => rej(err));
										});
									}),
								);
							}

							if (renewaled.length) {
								allPromise.push(
									new Promise((res, rej) => {
										return renewaled.map((item) => {
											renewalOperation({
												renewalProductOperationRequest: {
													product_operation_id: Number(item.operation_id),
												},
											})
												.unwrap()
												.then((result) => res(result))
												.catch((err) => rej(err));
										});
									}),
								);
							}

							if (userRole === UserRoles.admin) {
								allPromise.push(
									new Promise((res, rej) => {
										updateOperation({
											bodyUpdateOperations: {
												operations_payload: [
													...(updateOperations || [])
														.filter((el) => !!el.id)
														.map((el) => ({
															...el,
															id: el.id as number,
															date: el.date ? dayjs(el.date).format('YYYY-MM-DD') : '',
															count_in_gram: !isNaN(Number(el.count_in_gram))
																? (el.count_in_gram as number)
																: '',
															count_in_number: !isNaN(Number(el.count_in_number))
																? (el.count_in_number as number)
																: '',
															count_out_gram: !isNaN(Number(el.count_out_gram))
																? (el.count_out_gram as number)
																: '',
															count_out_number: !isNaN(Number(el.count_out_number))
																? (el.count_out_number as number)
																: '',
															wasted: el.wasted ? (el.wasted > 0 ? el.wasted : 0) : 0,
														})),
												],
												default_operations_payload:
													formProps?.default_product_operations
														?.filter((el) => !!el.id)
														.map((el) => ({
															...el,
															id: el.id as number,
															date: el.date ? dayjs(el.date).format('YYYY-MM-DD') : '',
															count_in_gram: !isNaN(Number(el.count_in_gram))
																? (el.count_in_gram as number)
																: '',
															count_in_number: !isNaN(Number(el.count_in_number))
																? (el.count_in_number as number)
																: '',
															count_out_gram: !isNaN(Number(el.count_out_gram))
																? (el.count_out_gram as number)
																: '',
															count_out_number: !isNaN(Number(el.count_out_number))
																? (el.count_out_number as number)
																: '',
															wasted: el.wasted ? (el.wasted > 0 ? el.wasted : 0) : 0,
														})) ?? [],
											},
										})
											.unwrap()
											.then((result) => res(result))
											.catch((err) => rej(err));
									}),
								);
							}

							if (userRole === UserRoles.operator || userRole === UserRoles.senior_operator) {
								allPromise.push(
									new Promise((res, rej) => {
										updateOperationOperator({
											bodyUpdateOperationsOperator: {
												operations_payload: [
													...(updateOperations || [])
														.filter((el) => !!el.id)
														.map((el) => ({
															...el,
															id: el.id as number,
															date: el.date ? dayjs(el.date).format('YYYY-MM-DD') : '',
															count_in_gram: !isNaN(Number(el.count_in_gram))
																? (el.count_in_gram as number)
																: '',
															count_in_number: !isNaN(Number(el.count_in_number))
																? (el.count_in_number as number)
																: '',
															count_out_gram: !isNaN(Number(el.count_out_gram))
																? (el.count_out_gram as number)
																: '',
															count_out_number: !isNaN(Number(el.count_out_number))
																? (el.count_out_number as number)
																: '',
															wasted: el.wasted ? (el.wasted > 0 ? el.wasted : 0) : 0,
														})),
												],
												default_operations_payload:
													formProps?.default_product_operations
														?.filter((el) => !!el.id)
														.map((el) => ({
															...el,
															id: el.id as number,
															date: el.date ? dayjs(el.date).format('YYYY-MM-DD') : '',
															count_in_gram: !isNaN(Number(el.count_in_gram))
																? (el.count_in_gram as number)
																: '',
															count_in_number: !isNaN(Number(el.count_in_number))
																? (el.count_in_number as number)
																: '',
															count_out_gram: !isNaN(Number(el.count_out_gram))
																? (el.count_out_gram as number)
																: '',
															count_out_number: !isNaN(Number(el.count_out_number))
																? (el.count_out_number as number)
																: '',
															wasted: el.wasted ? (el.wasted > 0 ? el.wasted : 0) : 0,
														})) ?? [],
											},
										})
											.unwrap()
											.then((result) => res(result))
											.catch((err) => rej(err));
									}),
								);
							}

							createOperations?.forEach((obj) => {
								allPromise.push(
									new Promise((res, rej) => {
										createOperation({
											productOperationCreate: {
												...obj,
												date: obj.date && dayjs(obj.date).format('YYYY-MM-DD'),
												product_tech_map_id: cardInfo?.id,
												user_id: userId,
											},
										})
											.unwrap()
											.then((result) => res(result))
											.catch((err) => rej(err));
									}),
								);
							});
						}
						if (changedV?.product && formProps.product.id && formProps.product.material_id) {
							allPromise.push(
								new Promise((res, rej) => {
									updateProduct({
										customProductRequestUpdate: {
											id: formProps.product.id as number,
											material_id: formProps.product.material_id as number,
											name: formProps?.product?.name ?? '',
											extra_name: formProps.product?.extra_name,
											symbol: formProps?.product?.symbol ?? '',
											extra_symbol: formProps?.product?.extra_symbol,
											approval_card: formProps.product?.approval_card,
											extra_approval_card: formProps.product?.extra_approval_card,
										},
									})
										.unwrap()
										.then((result) => {
											res(result);
										})
										.catch((err) => rej(err));
								}),
							);
						}
						if (changedV.product_plan) {
							if (
								[UserRoles.admin, UserRoles.senior_operator].includes(userRole as UserRoles) &&
								formProps.product_plan.id
							) {
								allPromise.push(
									new Promise((res, rej) => {
										updatePlan({
											productPlanUpdateRequest: {
												fio: formProps.product_plan?.fio,
												old_num: initialV?.num?.num,
												issuance_count: Number(
													String(formProps.product_plan?.issuance_count).replace(',', '.'),
												),
												list_number: formProps.product_plan?.list_number,
												return_count: formProps.product_plan?.return_count,
												return_count_kg: formProps.product_plan?.return_count_kg,
												return_count_grams: formProps.product_plan?.return_count_grams,
												return_count_square_meters:
													formProps.product_plan?.return_count_square_meters,
												return_fio: formProps.product_plan?.return_fio,
												return_number: formProps.product_plan?.return_number,
												summary: formProps.product_plan?.summary,
												tech_decision: formProps.product_plan?.tech_decision,
												id: formProps.product_plan.id as number,
												count: formProps.product_plan?.count,
												NR_kg: Number(
													convertNumberToNumberStringWithDot(formProps.product_plan?.NR_kg),
												),
												NR_meters: Number(
													convertNumberToNumberStringWithDot(
														formProps.product_plan?.NR_meters,
													),
												),
												NR_grams: Number(
													convertNumberToNumberStringWithDot(
														formProps.product_plan?.NR_grams,
													),
												),
												NR_square_meters: Number(
													convertNumberToNumberStringWithDot(
														formProps.product_plan?.NR_square_meters,
													),
												),
												product_releases: formProps.product_plan?.product_releases?.map(
													(el) => ({ ...el }),
												),
											},
										})
											.unwrap()
											.then((result) => res(result))
											.catch((err) => rej(err));
									}),
								);
							}

							if (userRole === UserRoles.operator && formProps.product_plan.id) {
								allPromise.push(
									new Promise((res, rej) => {
										updatePlanOperator({
											productPlanUpdateOperatorRequest: {
												id: formProps.product_plan.id as number,
												return_count: formProps?.product_plan?.return_count,
												return_number: formProps.product_plan?.return_number,
												return_fio: formProps.product_plan?.return_fio,
												list_number: formProps.product_plan?.list_number,
												tech_decision: formProps.product_plan?.tech_decision,
												summary: formProps.product_plan?.summary,
												product_releases: formProps.product_plan?.product_releases?.map(
													(el) => ({ ...el }),
												),
											},
										})
											.unwrap()
											.then((result) => res(result))
											.catch((err) => rej(err));
									}),
								);
							}
						}
					}

					const isSgdOutNotEmpty = formProps?.product_sgd_out?.filter((el) => {
						for (const key in el) {
							if (el[key as keyof ProductSgdOutDomain]) {
								return true;
							}
							return false;
						}
					}).length;

					if (allPromise.length || isSgdOutNotEmpty) {
						return Promise.allSettled(allPromise)
							.then(() => {
								// Правильная последовательность обновления: Операции – СГД

								if (changedV.product_sgd_out && isSgdOutNotEmpty) {
									const updateSgdOut = formProps?.product_sgd_out?.filter((el) => el.id);
									const createSgdOut = formProps?.product_sgd_out?.filter(
										(el) =>
											!el.id && (el.date || el.count || el.receiver || el.certificate || el.fio),
									);

									if (
										(userRole === UserRoles.operator ||
											userRole === UserRoles.senior_operator ||
											userRole === UserRoles.admin) &&
										cardInfo?.id
									) {
										const sgdOutCount =
											formProps?.product_sgd_out?.reduce(
												(acc, el) => acc + Number(el?.count ?? 0),
												0,
											) || 0;
										const acceptedAtSgdCount = cardInfo?.accepted_at_sgd_count || 0;

										if (acceptedAtSgdCount < sgdOutCount && userRole !== UserRoles.admin) {
											const errorMessage = `Сумма выданных деталей не может быть больше принятых. Принято - ${cardInfo?.accepted_at_sgd_count}`;
											showErrorToast(toast, {
												description: errorMessage,
											});
											throw new Error(errorMessage);
										} else {
											return updateSGDOutOperator({
												bodyPutSgdOutOperator: {
													update_payload:
														updateSgdOut
															?.filter((el) => !!el.id)
															.map((el) => ({
																...el,
																id: el.id!,
																product_tech_map_id: cardInfo.id!,
																assembly_tech_map_receiver_id:
																	el?.assembly_tech_map_receiver?.id ?? undefined,
															})) || [],
													create_payload:
														createSgdOut?.map((el) => ({
															...el,
															product_tech_map_id: cardInfo.id!,
															assembly_tech_map_receiver_id:
																el?.assembly_tech_map_receiver?.id ?? undefined,
														})) || [],
												},
											}).unwrap();
										}
									}
								}
							})
							.then(() => {
								showSuccessToast(toast, {
									title: 'Маршрутная карта обновлена',
									description: `Вы отредактировали карту № ${getTechCardName(cardInfo)}`,
								});
								setIsEditMode(false);
								form.reset();
								if (
									!!formProps.number &&
									initialV.number?.toString() !== formProps.number?.toString()
								) {
									navigate(LINK_TO_TECH_CARD_INFO(Number(formProps.number)));
								} else {
									getCardInfo.refetch();
								}
							})
							.catch(() => {
								showErrorToast(toast, {
									title: 'Не удалось сохранить',
									description: `Произошла ошибка при сохранении карты № ${getTechCardName(cardInfo)}`,
								});
							});
					}
				})
				.catch((err) => {
					if (err?.status) {
						showErrorToast(toast, {
							description: `Карта №
							${cardInfo?.symbol ? cardInfo.symbol + '-' + formProps?.number : formProps?.number}
							 уже существует`,
						});
					}
				})
				.finally(() => {
					if (exitAfterSubmit.exitAfterSubmit) {
						navigate(TECH_CARDS_PATH);
					}
					setIsUpdating(false);
				});
		}
	};

	useEffect(() => {
		if (getCardInfo.isError) {
			console.error(getCardInfo?.error);
			showErrorToast(toast, { description: 'При получении техкарты произошла ошибка' });
			navigate(TECH_CARDS_PATH);
		}
	}, [getCardInfo.isError]);

	useEffect(() => {
		if (isAssemblyError) {
			console.error(isAssemblyError);
			showErrorToast(toast, { description: 'При получении сборочной техкарты произошла ошибка' });
			navigate(TECH_CARDS_PATH);
		}
	}, [isAssemblyError]);

	return {
		assemblyCard,
		isAssemblyTechMap,
		handleAssemblySubmit,
		assemblyCardInfo,
		// объект с данными о тех-карте
		cardInfo,
		// объект с состоянием получения объекта карты (isSuccess, isFailed, isLoading)
		getCardInfo,
		// номер карты (из урла)
		cardNumber,
		// режим создания новой карты
		isCreationMode,
		// режим редактирования существующей карты
		isEditMode,
		setIsEditMode,
		isInitialEditMode,
		userRole,
		// сабмит для обновления существующей карты
		handleSubmit,
		// изменение статуса карты
		changeStatusToCanceled,
		// флаг обновления
		isUpdating,
		setIsUpdating,
		cancelAssemblyTechMap,
		// функция для ручной инвалидации запроса получения тех-карты
		refetchTechCard: getCardInfo.refetch,
		submitModal,
		setSubmitModal,
	};
};
