import { CounteragentResponse } from '@/shared/state/api/swagger';
import { CustomInput } from '@/shared';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { counterAgentFormSchema } from '../model/model';
import { CounterAgentFormProps } from '../model/counter-agent-form.types';

export const CounterAgentForm = forwardRef<HTMLFormElement, CounterAgentFormProps>(
	({ selectedAgent, onSubmit }, ref) => {
		const { control, handleSubmit } = useForm<CounteragentResponse>({
			defaultValues: { ...selectedAgent },
			mode: 'all',
			resolver: yupResolver(counterAgentFormSchema),
		});

		return (
			<form onSubmit={handleSubmit(onSubmit)} ref={ref}>
				<Controller
					control={control}
					name="name"
					render={({ field }) => (
						<CustomInput size="md" {...field} isInvalid={!field.value} label="Организация" />
					)}
				/>
			</form>
		);
	},
);
