import type { FC } from 'react';

import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { Flex } from '@chakra-ui-kraud/react';
import { CustomInput } from '@/shared/components/custom-input';

import styles from './preform-form.module.scss';
import { ReactSortable } from 'react-sortablejs';
import { FormBlock } from '@/shared/components/form-block';
import { FormFieldOperation } from '@/shared/components/form-field-operation';
import { TECH_MAP_ADDITIONAL_FILE_EXTENSIONS } from '@/entities/tech-map';
import { useLazyGetFileQuery } from '@/shared/state/api/swagger';
import { FileDnD, handleDownloadFile } from '@/shared';
import { IPreformFormData } from '../model/interface/preform-form.types';

export const PreformForm: FC = () => {
	const { control, setValue, getValues } = useFormContext<IPreformFormData>();
	const [getFile] = useLazyGetFileQuery();

	const sortableOperations = useWatch({ control, name: 'operation_types' });

	return (
		<form>
			<Flex direction="column" gap="32px">
				<FormBlock title="Процесс заготовки">
					<Controller
						control={control}
						name="name"
						render={({ field, fieldState }) => (
							<CustomInput size="md" {...field} label="Наименование" isInvalid={!!fieldState.error} />
						)}
					/>
				</FormBlock>
				<FormBlock title="Приложения к МК">
					<Controller
						control={control}
						name="file"
						render={({ field }) => (
							<FileDnD
								maxFiles={1}
								multiple={false}
								description={'Нажмите, чтобы загрузить файл или перетащите его сюда'}
								onDropAccepted={(file: File[]) => field.onChange(file[0])}
								accepted={!!field.value}
								accept={TECH_MAP_ADDITIONAL_FILE_EXTENSIONS}
								onDownload={
									getValues('file.id')
										? () => {
												const id = getValues('file.id');
												if (id) {
													getFile({ id })
														.unwrap()
														.then((res) =>
															handleDownloadFile(
																res as unknown as Blob,
																`${getValues('file.filename')}`,
															),
														)
														.catch((error) => {
															console.error('Ошибка при получении файла', error);
														});
												}
										  }
										: undefined
								}
							/>
						)}
					/>
				</FormBlock>
				<FormBlock
					title="Операции"
					action="Добавить операцию"
					onAction={() => {
						setValue('operation_types', [
							...(sortableOperations ?? []),
							// Новая пустая операция
							{
								name: '',
								id: new Date().getTime() * -1,
								step_id: (sortableOperations[sortableOperations.length - 1]?.step_id || 0) + 5,
							},
						]);
					}}
				>
					<Flex direction="column" gap="10px">
						<ReactSortable
							handle=".handle"
							animation={150}
							list={sortableOperations}
							setList={(newState) => {
								setValue(
									'operation_types',
									newState
										.filter((el) => !!el)
										.map((operation, idx) => ({
											...operation,
											step_id: (idx + 1) * 5,
										})),
								);
							}}
							className={styles['operations']}
						>
							{sortableOperations.map((operation: any, idx: number) => (
								<Controller
									key={operation.id}
									control={control}
									name={`operation_types.${idx}`}
									render={({ field, fieldState }) => {
										return (
											<FormFieldOperation
												step={operation.step_id}
												id={operation.id}
												onDelete={() => {
													const newOperations = sortableOperations
														.filter((operation: any) => operation.id !== field.value.id)
														.map((operation: any, idx: number) => ({
															...operation,
															step_id: (idx + 1) * 5,
														}));
													setValue('operation_types', newOperations);
												}}
											>
												<CustomInput
													size="sm"
													label="Наименование операции"
													value={field.value.name}
													isInvalid={!!fieldState.error}
													onChange={(e) => {
														setValue(`operation_types.${idx}`, {
															...getValues(`operation_types.${idx}`),
															name: e.target.value,
														});
													}}
												/>
											</FormFieldOperation>
										);
									}}
								/>
							))}
						</ReactSortable>
					</Flex>
				</FormBlock>
			</Flex>
		</form>
	);
};
